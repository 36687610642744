import { FC } from 'react';

import type { LinkGradientOutlinePropsType } from './type';

import { StyledLink } from './styled';

const LinkGradientOutline: FC<LinkGradientOutlinePropsType> = (props) => {
  return <StyledLink {...props} />;
};

export default LinkGradientOutline;
