import type { NullableDate, NullableOption } from '@appTypes';

import { Category } from '@configs/graphql/generated';

export enum SearchByCategoryEnum {
  ALL = 'all',
  EVENTS = 'events',
  PEOPLE = 'people',
}

export interface ISearchValues {
  page: number;
  count?: number;
  search: string;
  online: boolean;
  categories: Category[];
  endDate: NullableDate;
  endHour: NullableOption;
  startDate: NullableDate;
  location: NullableOption;
  isNoEndDateTime: boolean;
  endMinute: NullableOption;
  startHour: NullableOption;
  startMinute: NullableOption;
  searchBy: SearchByCategoryEnum;
}

export interface IOptimizedData {
  name: string;
  link: string;
  imgUrl: string;
  dates: any;
}
