import { Product } from '@configs/graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IStringByString<T = any> {
  [key: string]: T;
}

export interface ICard {
  id: string;
  key: string;
  avatar: {
    uri: string;
  };
  eventImg: string;
  username: string;
  title: string;
  available: string;
  price: string;
  location: string;
  date: string;
  tags: { name: string; _id: string; _key?: string }[];
  products: Product[];
}

export enum StorageManipulationMethodsEnum {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  RESET = 'reset',
}

export enum StorageManipulationKeysEnum {
  CREATE_EVENTS = 'create_event',
}

export enum EventsContent {
  BasicEventPage = 1,
  EventDetailsPage = 2,
  CreateTicketPage = 3,
  ReviewPage = 4,
}
