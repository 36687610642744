import Box from '@mui/material/Box';
import styled from 'styled-components/macro';

export const BackgroundContainer = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #090909;
  overflow: hidden;
`;

export const CenteredContainer = styled(Box)`
  max-width: 1280px;
  width: var(--100vw);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
