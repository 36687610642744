import Box from '@mui/material/Box';
import styled from 'styled-components/macro';
import { isSafari } from 'react-device-detect';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgSearchSvg from '@assets/images/background/page-bg-search.svg';
import pageBgSearchPng from '@assets/images/background/page-bg-search.png';

export const BigImage = styled(Box)`
  width: 6243px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgSearchPng : pageBgSearchSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 6243px 11923px;
  transform: translate(-39.8%, -6110px);
`;
