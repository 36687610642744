import { ICreateEvent, IEventDetails, INftTicketDetails, ITicketDetails } from './types';

export const basicEvent: ICreateEvent = {
  image: null,
  name: '',
  location: null,
  onlineEvent: false,
  startDate: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
  endDate: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
  website: '',
  categories: [],
  tags: [],
  avatar: {
    uri: '',
  },
  recurringEvent: false,
  recurringEventDate: [],
  saveRecurringEventDate: null,
};

export const eventDetails: IEventDetails = {
  description: '',
  coHosts: [],
  plusOne: false,
  isPrivate: false,
};

export const ticketDetails: ITicketDetails = {
  name: '',
  ticketDescription: '',
  availableOrderQuantity: { min: null, max: null },
  price: '',
  free: false,
  id: '',
  saleBegins: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
  saleEnds: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
  eventDateStart: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
  eventDateEnd: {
    date: null,
    hours: null,
    minutes: null,
    timeOfDay: 'PM',
  },
};

export const nftTicketDetails: INftTicketDetails = {
  ...ticketDetails,
  propertiesAndPerks: '',
  passthroughPercentage: { rate: '', walletAddress: '' },
  royalties: { rate: '', walletAddress: '' },
};

export interface IInitialCreateEventData {
  createEvent: ICreateEvent;
  createTicket: ITicketDetails[];
  createEventDetails: IEventDetails;
}

export const initialCreateEventData: IInitialCreateEventData = {
  createEvent: basicEvent,
  createEventDetails: eventDetails,
  createTicket: [] as ITicketDetails[],
};
