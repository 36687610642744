import { memo } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { ProviderId } from 'firebase/auth';

import { PagesEnum } from '@appTypes/pages';

import { useAuth } from '@hooks';
import * as Layouts from '@layouts';
import { PageLoader } from '@common/Loaders';

const PublicRoutes = () => {
  const { firebaseUser, isUserExists, isUserLoaded } = useAuth();
  const location = useLocation();

  if (!isUserLoaded) {
    return (
      <Layouts.Home>
        <PageLoader />
      </Layouts.Home>
    );
  }

  if (
    !firebaseUser ||
    (firebaseUser && firebaseUser.providerData[0].providerId === ProviderId.PASSWORD && !firebaseUser.emailVerified)
  ) {
    return <Layouts.Public />;
  }

  return (
    <Navigate to={isUserExists ? PagesEnum.HOME : PagesEnum.COMPLETE_SIGN_UP} state={{ from: location }} replace />
  );
};

export default memo(PublicRoutes);
