import { SVGProps } from 'react';

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" fill="none" viewBox="0 0 49 48" {...props}>
    <circle cx="24.402" cy="24" r="23.5" stroke="#fff" />
    <path
      fill="#fff"
      d="M27.777 24c0 1.875-1.5 3.375-3.375 3.375A3.361 3.361 0 0121.027 24c0-1.875 1.5-3.375 3.375-3.375s3.375 1.5 3.375 3.375zm4.75-4v8c-.125 2.625-1.375 4-4.125 4.125h-8c-2.75-.125-4-1.375-4.125-4.125v-4-4c.125-2.75 1.375-4 4.125-4.125h8c2.75.125 4 1.375 4.125 4.125zm-3 4c0-2.875-2.25-5.125-5.125-5.125s-5.125 2.25-5.125 5.125 2.25 5.125 5.125 5.125 5.125-2.25 5.125-5.125zm1.375-5.375c0-.625-.5-1.25-1.25-1.25s-1.25.5-1.25 1.25.5 1.25 1.25 1.25 1.25-.5 1.25-1.25zm3.5 5.375v-4.125c-.125-3.625-2.125-5.625-5.875-5.875h-8.25c-3.625.25-5.625 2.25-5.875 5.875v8.25c.125 3.625 2.125 5.625 5.875 5.875h8.25c3.625-.125 5.625-2.125 5.875-5.875V24z"
    />
  </svg>
);

export default InstagramIcon;
