import { BackgroundContainer } from '@common/Backgrounds/styled';
import { BigImage } from './styled';

const EventInfoBackground = () => (
  <BackgroundContainer>
    <BigImage />
  </BackgroundContainer>
);

export default EventInfoBackground;
