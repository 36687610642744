import Box from '@mui/material/Box';
import styled from 'styled-components/macro';
import { isSafari } from 'react-device-detect';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgUserSvg from '@assets/images/background/page-bg-user.svg';
import pageBgUserPng from '@assets/images/background/page-bg-user.png';

export const BigImage = styled(Box)`
  width: 6243px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgUserPng : pageBgUserSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 6243px 11330px;
  transform: translate(-39.7%, -5800px);
`;
