import bgOvals from '@assets/images/bg-ovals.png';
import { BackgroundContainer, CenteredContainer } from '@common/Backgrounds/styled';
import { BigImage, RightOvalsWrapper } from './styled';

const AuthBackground = () => (
  <BackgroundContainer>
    <BigImage />
    <CenteredContainer>
      <RightOvalsWrapper>
        <img src={bgOvals} alt="ovals" />
      </RightOvalsWrapper>
    </CenteredContainer>
  </BackgroundContainer>
);

export default AuthBackground;
