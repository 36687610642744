import { Components, css } from '@mui/material/styles';

import { resetCSS } from '@assets/styles/reset';
import { FontFamilies } from '@theme/fonts';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: css`
    ${resetCSS};

    :root {
      --toastify-font-family: ${FontFamilies.HalvarBreitschrift};
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    body {
      font-family: ${FontFamilies.HalvarBreitschrift};
      /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif; */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    body,
    #root {
      min-height: var(--100vh);
      display: flex;
      flex-direction: column;
    }

    #root {
      position: relative;
      // TODO: create colors list
      background: #090909;
      color: #fff;
    }

    main {
      flex: 1;
      color: #fff;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    footer {
      z-index: 1;
    }

    .Toastify__toast-body {
      font-size: 14px;
    }

    input:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
      caret-color: #fff;
    }
  `,
};
