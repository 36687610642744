import styled from 'styled-components/macro';
import { isSafari } from 'react-device-detect';
import { Box } from '@mui/material';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgTicketInfoSvg from '@assets/images/background/page-bg-ticket-info.svg';
import pageBgTicketInfoPng from '@assets/images/background/page-bg-ticket-info.png';

export const BigImage = styled(Box)`
  width: 4980px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgTicketInfoPng : pageBgTicketInfoSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 4980px 11278px;
  transform: translate(-50%, -6570px);

  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: translate(-60%, -7570px);
  }
`;
