import { useNavigate } from 'react-router-dom';

import { PagesEnum } from '@appTypes/pages';

import HeaderLogoIcon from '@images/HeaderLogoIcon';
import CloseIcon from '@images/CloseIcon';
import UserPart from '../UserPart';
import Navbar from '../Navbar';
import { BurgerButton, LogoContainer } from '../../styled';
import { Container, ContentContainer, Heading } from './styled';

interface IMobileMenuProps {
  isOpenMobileMenu: boolean;
  handleCloseMobileMenu: () => void;
}

const MobileMenu = ({ isOpenMobileMenu, handleCloseMobileMenu }: IMobileMenuProps) => {
  const navigate = useNavigate();

  return (
    <Container isOpen={isOpenMobileMenu} onClick={handleCloseMobileMenu}>
      <ContentContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Heading direction="row">
          <LogoContainer to={PagesEnum.HOME} onClick={handleCloseMobileMenu}>
            <HeaderLogoIcon />
          </LogoContainer>
          <BurgerButton onClick={handleCloseMobileMenu}>
            <CloseIcon />
          </BurgerButton>
        </Heading>
        <UserPart fromMobileMenu handleCloseMobileMenu={handleCloseMobileMenu} />
        <Navbar
          fromMobileMenu
          handleCloseMobileMenu={() => {
            navigate(PagesEnum.SEARCH);
            handleCloseMobileMenu();
          }}
        />
      </ContentContainer>
    </Container>
  );
};

export default MobileMenu;
