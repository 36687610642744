import { Dispatch, SetStateAction } from 'react';

import { LazyQueryExecFunction } from '@apollo/client';

import {
  Exact,
  GetAllEventsQuery,
  SearchEventInput,
  SearchUserInput,
  UserSearchQuery,
} from '@configs/graphql/generated';
import { ISearchData } from './types';
import { SearchSymbolEnum, SuggestionViewCaseEnum } from './types';

interface IQueryFilters {
  count: number;
  text?: string;
  filters?: { tags: [string] };
}

const convertEventData = ({ data }: { data: GetAllEventsQuery }) => {
  const array = data?.eventSearch || [];

  return array.map((event) => {
    return {
      _key: event?._key || '',
      name: event?.name || '',
      link: event?._key ? `event/${event?._key}` : '',
    };
  });
};

const convertUserData = ({ data }: { data: UserSearchQuery }) => {
  const array = data?.userSearch || [];

  return array.map((user) => {
    return {
      _key: user?._key || '',
      name: user?.firstName || '',
      link: user?.authId ? `user/${user?.authId}` : '',
      avatar: user?.avatar?.uri || '',
    };
  });
};

interface IHandleSearchRequest {
  searchValue: string;
  getEvents: LazyQueryExecFunction<
    GetAllEventsQuery,
    Exact<{
      query: SearchEventInput;
    }>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
  getPeoples: LazyQueryExecFunction<
    UserSearchQuery,
    Exact<{
      query: SearchUserInput;
    }>
  >;
  setSearchData: Dispatch<SetStateAction<ISearchData[]>>;
  setLastSearch: Dispatch<SetStateAction<SuggestionViewCaseEnum>>;
}

export const handleSearchRequest = ({
  getPeoples,
  getEvents,
  setLoading,
  searchValue,
  setLastSearch,
  setSearchData,
}: IHandleSearchRequest) => {
  setLoading(true);

  if (!searchValue) {
    return;
  }

  const query: IQueryFilters = {
    count: 10,
  };

  const usersSearchCase = () => {
    getPeoples({
      variables: {
        query: {
          count: 10,
          text: searchValue.substring(1),
        },
      },
    }).then(({ data }) => {
      if (!data) {
        return;
      }

      const optimizedData = convertUserData({ data });

      setLastSearch(SuggestionViewCaseEnum.User);
      setLoading(false);
      setSearchData(optimizedData);
    });
  };

  const eventsSearchCases = () => {
    getEvents({
      variables: {
        query:
          searchValue[0] == SearchSymbolEnum.Event
            ? {
                ...query,
                filters: {
                  tags: [`tags/${searchValue.substring(1)}`],
                },
              }
            : {
                ...query,
                text: searchValue,
              },
      },
    }).then(({ data }) => {
      if (!data) {
        return;
      }

      const optimizedData = convertEventData({ data });

      setLastSearch(SuggestionViewCaseEnum.Event);
      setSearchData(optimizedData);
      setLoading(false);
    });
  };

  switch (searchValue[0]) {
    case SearchSymbolEnum.User:
      usersSearchCase();
      break;
    case SearchSymbolEnum.Event:
    case SearchSymbolEnum.Empty:
    default:
      eventsSearchCases();
      break;
  }
};
