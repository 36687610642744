import styled from 'styled-components/macro';
import Box from '@mui/material/Box';

export const Content = styled(Box)`
  position: relative;
  width: 100%;
  max-width: 1048px;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.colors.text.white};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 15px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: auto;
  }
`;
