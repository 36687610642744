import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { PrivateHeader } from '@shared/Header';
import Footer from '@shared/Footer';

const PrivateLayout = () => (
  <>
    <PrivateHeader />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>
);

export default memo(PrivateLayout);
