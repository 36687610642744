import styled from 'styled-components/macro';
import Box from '@mui/material/Box';

export const Container = styled(Box)`
  margin: 2px 0 0;
  padding: 0 10px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.colors.opacity.white.zeroFive};
  backdrop-filter: blur(32px); // FIXME: attention, it has minimal browser support
  border: 1px solid ${({ theme }) => theme.palette.colors.opacity.white.one};
  border-radius: 4px;

  .username {
    margin-left: 15px;
  }
`;
