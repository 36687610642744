import { memo, useEffect, useState } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ProviderId } from 'firebase/auth';

import { PagesEnum } from '@appTypes/pages';

import { useAuth } from '@hooks';
import * as Layouts from '@layouts';
import { PageLoader } from '@common/Loaders';

const PrivateRoutes = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { firebaseUser, isUserExists, isUserLoaded } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (firebaseUser && isUserLoaded && !isUserExists && location.pathname !== PagesEnum.COMPLETE_SIGN_UP) {
      navigate(PagesEnum.COMPLETE_SIGN_UP, { replace: true });
    }

    setIsMounted(true);
  }, [isUserExists, location.pathname, navigate, firebaseUser, isUserLoaded]);

  if (!isMounted || !isUserLoaded) {
    return (
      <Layouts.Home>
        <PageLoader />
      </Layouts.Home>
    );
  }

  if (
    !firebaseUser ||
    (firebaseUser && firebaseUser.providerData[0].providerId === ProviderId.PASSWORD && !firebaseUser.emailVerified)
  ) {
    return <Navigate to={PagesEnum.SIGN_IN} state={{ from: location }} replace />;
  }

  return <Layouts.Private />;
};

export default memo(PrivateRoutes);
