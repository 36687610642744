import styled from 'styled-components/macro';
import { Link, NavLink, LinkProps, NavLinkProps } from 'react-router-dom';

import { getGradientBorder } from '@styles/styleConstants';
import { LinkGradientOutlinePropsType } from './type';

export const StyledLink = styled(({ isNavLink, ...restProps }) => {
  return isNavLink ? <NavLink {...(restProps as NavLinkProps)} /> : <Link {...(restProps as LinkProps)} />;
})<LinkGradientOutlinePropsType>`
  ${getGradientBorder()};
`;
