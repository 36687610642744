/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchValues, SearchByCategoryEnum } from '@common/SearchBar/types';

export const searchStoreName = 'search';

const initialState: ISearchValues = {
  page: 1,
  search: '',
  searchBy: SearchByCategoryEnum.ALL,
  location: null,
  categories: [],
  startDate: null,
  endDate: null,
  online: false,
  startHour: null,
  startMinute: null,
  endHour: null,
  endMinute: null,
  isNoEndDateTime: false,
};

export const searchSlice = createSlice({
  name: searchStoreName,
  initialState,
  reducers: {
    setSearchResult: (state, action: PayloadAction<ISearchValues>) => {
      state.page = action.payload.page;
      state.search = action.payload.search;
      state.endHour = action.payload.endHour;
      state.endDate = action.payload.endDate;
      state.searchBy = action.payload.searchBy;
      state.online = action.payload.online;
      state.location = action.payload.location;
      state.endMinute = action.payload.endMinute;
      state.startDate = action.payload.startDate;
      state.startHour = action.payload.startHour;
      state.categories = action.payload.categories;
      state.startMinute = action.payload.startMinute;
      state.isNoEndDateTime = action.payload.isNoEndDateTime;
    },
    setLoadMorePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setSearchResult, setLoadMorePage } = searchSlice.actions;
export default searchSlice.reducer;
