import { memo, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { PagesEnum } from '@appTypes/pages';

import HeaderLogoIcon from '@images/HeaderLogoIcon';
import { LinkGradientOutline } from '@common/Links';
import { LogoContainer, StyledAppBar, StyledToolbar, StyledStack, StyledButton, StyledLink } from './styled';

const PublicHeader = () => {
  const [state, setState] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    setState(location.pathname);
  }, [location.pathname]);

  const renderButtons = () => {
    switch (state) {
      case '/auth/sign-in':
        return (
          <>
            <StyledLink to={PagesEnum.SIGN_UP}>
              <StyledButton>SIGN UP</StyledButton>
            </StyledLink>
            <LinkGradientOutline to={PagesEnum.SIGN_IN}>
              <StyledButton isBordered>SIGN IN</StyledButton>
            </LinkGradientOutline>
          </>
        );
      default:
        return (
          <>
            <LinkGradientOutline to={PagesEnum.SIGN_UP}>
              <StyledButton>SIGN UP</StyledButton>
            </LinkGradientOutline>
            <StyledLink to={PagesEnum.SIGN_IN}>
              <StyledButton isBordered>SIGN IN</StyledButton>
            </StyledLink>
          </>
        );
    }
  };

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>
        <LogoContainer to={PagesEnum.SIGN_IN}>
          <HeaderLogoIcon />
        </LogoContainer>
        <StyledStack direction="row" spacing={1}>
          {renderButtons()}
        </StyledStack>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default memo(PublicHeader);
