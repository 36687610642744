import { memo } from 'react';

import { createGlobalStyle } from 'styled-components';

import useViewport, { IViewPort } from '@hooks/useViewport';

const CSSRootScreenSizeVariables = () => {
  const viewport = useViewport();

  return <RootVariables {...viewport} />;
};

export default memo(CSSRootScreenSizeVariables);

const RootVariables = createGlobalStyle<IViewPort>`
  :root {
    --100vw: ${({ width }) => width}px;
    --100vh: ${({ height }) => height}px;
  }
`;
