import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#fff"
      d="M17.787 16.69l-5.02-5.075a7.174 7.174 0 00-.873-9.819 7.15 7.15 0 00-9.836.341 7.172 7.172 0 00-.192 9.856 7.151 7.151 0 009.816.724l4.988 5.043a.772.772 0 001.33-.515.78.78 0 00-.213-.555zM7.144 12.778a5.645 5.645 0 01-3.584-1.286A5.66 5.66 0 012.165 4.45a5.642 5.642 0 019.68-.472 5.666 5.666 0 01-2.538 8.373 5.607 5.607 0 01-2.163.427z"
      opacity="0.7"
    />
  </svg>
);

export default SearchIcon;
