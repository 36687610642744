import Box from '@mui/material/Box';
import { isSafari } from 'react-device-detect';
import styled, { css } from 'styled-components/macro';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgCompleteSignUpSvg from '@assets/images/background/page-bg-complete-sign-up.svg';
import pageBgCompleteSignUpPng from '@assets/images/background/page-bg-complete-sign-up.png';
import lineFatSvg from '@assets/images/line-fat.svg';
import lineThinSvg from '@assets/images/line-thin.svg';

export const BigImage = styled(Box)`
  width: 6242.84px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgCompleteSignUpPng : pageBgCompleteSignUpSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 6243px 8511px;
  transform: translate(-39.75%, -4353px);
`;

export const StyledSvg = styled(Box)<{ lineSize: 'fat' | 'thin' }>`
  height: ${BigBackgroundImageHeight};
  position: absolute;
  left: 50%;
  background-repeat: repeat-y;
  background-size: contain;

  ${({ lineSize }) =>
    lineSize === 'thin'
      ? css`
          width: 698px;
          top: -168px;
          background-image: url(${lineThinSvg});
          background-size: 698px 1700px;
          transform: translateX(-0.4%);
        `
      : css`
          width: 787px;
          top: -343px;
          background-image: url(${lineFatSvg});
          background-size: 787px 1700px;
          transform: translateX(-15.4%);
        `};
`;
