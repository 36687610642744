import { BackgroundContainer, CenteredContainer } from '@common/Backgrounds/styled';
import { BigImage, StyledSvg } from './styled';

type UserInfoBackgroundProps = { withLine: true; lineSize: 'fat' | 'thin' } | { withLine?: false };

const CompleteSignUpBackground = (props: UserInfoBackgroundProps) => (
  <BackgroundContainer>
    <BigImage />
    <CenteredContainer>{props.withLine && <StyledSvg lineSize={props.lineSize} />}</CenteredContainer>
  </BackgroundContainer>
);

export default CompleteSignUpBackground;
