import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { UserBackground } from '@common/Backgrounds';
import { Content } from '@layouts/styled';

const UserLayout = () => (
  <>
    <UserBackground />
    <Content>
      <Outlet />
    </Content>
  </>
);

export default memo(UserLayout);
