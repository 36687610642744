import { ApolloClient, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import cache from '@configs/graphql/cache';
import { GRAPHQL_URL, ENVIRONMENT } from '@utils/constants';
import { auth } from '@configs/firebase';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = from([errorLink, new HttpLink({ uri: GRAPHQL_URL })]);

export const getAndSetUserTokenToApolloClient = async () => {
  const token = await auth.currentUser?.getIdToken(false); // set true if you want to force refresh token

  const newLink = from([
    errorLink,
    new HttpLink({ uri: GRAPHQL_URL, headers: { Authorization: token ? `Bearer ${token}` : '' } }),
  ]);

  client.setLink(newLink);

  return token || null;
};

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: ENVIRONMENT.isDev,
});

export default client;
