import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export const StyledAppBar = styled(AppBar)`
  height: 80px;
  backdrop-filter: blur(24px);
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 60px;
  }
`;

export const StyledToolbar = styled(Toolbar)<{ exists?: string }>`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${({ exists }) => exists};

  padding-left: 16px;
  padding-right: 16px;
  min-height: 80px;

  @media (min-width: 600px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 60px;
    grid-template-columns: 1fr 1fr;
  }
  & > {
    width: min-content;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;

  svg {
    ${({ theme }) => theme.breakpoints.down('md')} {
      height: 19.266212463378906px;
      width: 63.16493225097656px;
    }
  }
`;

export const StyledStack = styled(Stack)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const BurgerButton = styled(Button)`
  margin-right: -12px;
  margin-left: auto;
  padding: 10px;
  display: none;
  color: ${({ theme }) => theme.palette.colors.text.white};

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: inline-block;
  }
`;
