import { useEffect } from 'react';

enum CEEEnum {
  endDate = 'endDate',
  location = 'location',
  startDate = 'startDate',
  eventDateEnd = 'eventDateEnd',
  eventDateStart = 'eventDateStart',
  saleBegins = 'saleBegins',
  saleEnds = 'saleEnds',
  recurringEventDate = 'recurringEventDate',
}

const useClearEventManipulationValidationData = ({ errors, values, clearErrors }: any) => {
  useEffect(() => {
    const errorKeys = Object.keys(errors || {});

    errorKeys.forEach((fieldName) => {
      const field = fieldName as CEEEnum;
      const value = values[field];

      const collectionOfUpdatesFields = [
        CEEEnum.endDate,
        CEEEnum.startDate,
        CEEEnum.eventDateEnd,
        CEEEnum.eventDateStart,
        CEEEnum.saleBegins,
        CEEEnum.saleEnds,
      ];

      if (collectionOfUpdatesFields.includes(field) && value.date && errors[field]?.date) {
        clearErrors(`${field}.date`);
      } else if (field === CEEEnum.recurringEventDate && values[field]?.length) {
        clearErrors(CEEEnum.recurringEventDate);
      } else if (field === CEEEnum.location && Object.values(value || {}).length && errors?.location) {
        clearErrors(CEEEnum.location);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
};

export default useClearEventManipulationValidationData;
