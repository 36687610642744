import { Components } from '@mui/material/styles';

import { COLORS, COLORS_OPACITY } from '@theme/palette';

export const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      color: COLORS.white,
      // '&.MuiFormLabel-filled, &.Mui-focused': {},
    },
    standard: {},
    filled: {},
    outlined: {},
    error: {},
    disabled: {},
    animated: {},
    asterisk: {},
    required: {},
    formControl: {},
    shrink: {},
    sizeSmall: {},
  },
};

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: '100%',
      // height: '48px',
      color: COLORS.white,

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(243, 70, 77, 0.7)',
        },
      },

      '.Mui-disabled': {
        opacity: 1,
        '-webkit-text-fill-color': COLORS_OPACITY.white.eight,
      },
    },
  },
};

export const MuiFormHelperText: Components['MuiFormHelperText'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      margin: '0px',
      color: COLORS.brightRed,
    },
  },
};

export const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: COLORS.white,
      background: COLORS_OPACITY.white.one,
      borderRadius: '4px',

      '&.Mui-focused, &:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS_OPACITY.white.two,
        },

        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.brightRed,
        },
      },
    },
    input: {
      '&::placeholder': {
        color: COLORS_OPACITY.white.four,
      },
    },
    inputMultiline: {},
    inputAdornedStart: {},
    inputAdornedEnd: {},
    inputSizeSmall: {},
    sizeSmall: {},
    adornedStart: {},
    adornedEnd: {},
    focused: {},
    disabled: {},
    error: {},
    multiline: {},
    notchedOutline: {
      borderColor: COLORS_OPACITY.white.two,
    },
    colorSecondary: {},
  },
};

export const MuiInput: Components['MuiInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: COLORS.white,
    },
    input: {},
  },
};
