import { Suspense, useEffect, memo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PagesEnum } from '@appTypes/pages';

import { store } from '@store';
import { auth } from '@configs/firebase';
import { useAuth } from '@hooks';
import * as Pages from '@pages';
import * as Layouts from '@layouts';
import { PageLoader } from '@common/Loaders';
import { ENVIRONMENT } from '@utils/constants';
import PageScrollToTop from '@common/PageScrollToTop';
import { setYoutubeUrl } from '@store/slices/configsSlice';
import { setCategories } from '@store/slices/categoriesSlice';
import { setUserCredentials, setBookmarkEvents, setAuthError, setOwnFollowing } from '@store/slices/authSlice';
import { PublicRoutes, PrivateRoutes } from '@common/Routes';
import ApolloClient, { getAndSetUserTokenToApolloClient } from '@configs/graphql';
import { Category, UserFullDataDocument, useUserMergedQueriesLazyQuery } from '@configs/graphql/generated';

auth.onAuthStateChanged(async (firebaseUser) => {
  const token = await getAndSetUserTokenToApolloClient();

  let user = null;
  let error = false;
  let isUserExists = false;

  if (firebaseUser && token) {
    try {
      const { data } = await ApolloClient.query({
        query: UserFullDataDocument,
        variables: { authId: firebaseUser.uid },
      });

      if (data.user) {
        user = data.user;
      }

      isUserExists = !!data.user;
      // eslint-disable-next-line no-empty
    } catch {
      error = true;
    }
  }

  if (error) {
    error = false;

    store.dispatch(
      setUserCredentials({ firebaseUser: null, token: null, user: null, isUserExists: false, isUserLoaded: true }),
    );
    store.dispatch(setAuthError({ name: '', message: 'Something went wrong' }));
  } else {
    store.dispatch(setUserCredentials({ firebaseUser, token, user, isUserExists, isUserLoaded: true }));

    if (user?.bookmarkEvents?.length) {
      store.dispatch(setBookmarkEvents({ bookmarkEvents: user.bookmarkEvents.map((e: any) => e?._key as string) }));
    }

    if (ENVIRONMENT.isDev) {
      // eslint-disable-next-line no-console
      console.group('UserCredentials');
      // eslint-disable-next-line no-console
      console.log({ firebaseUser, token, user, isUserExists });
      // eslint-disable-next-line no-console
      console.groupEnd();
    }
  }
});

const App = () => {
  const dispatch = useDispatch();
  const { isFirebaseUserLoaded, user } = useAuth();

  const [getUserInfo] = useUserMergedQueriesLazyQuery();

  useEffect(() => {
    if (user?.authId && user?.authId) {
      getUserInfo({
        variables: {
          authId: user?.authId,
          following: `${user?._id}`,
        },
        fetchPolicy: 'network-only',
      }).then((response) => {
        if (response?.data?.categories) {
          dispatch(setCategories(response.data.categories as Category[]));
        }

        if (response?.data?.following) {
          dispatch(setOwnFollowing(response.data?.following));
        }

        if (response.data?.configs) {
          dispatch(setYoutubeUrl(response.data?.configs?.more_info_url || ''));
        }
      });
    }
  }, [user]);

  if (!isFirebaseUserLoaded) {
    return (
      <Layouts.Home>
        <PageLoader />
      </Layouts.Home>
    );
  }

  return (
    <PageScrollToTop>
      <Suspense
        fallback={
          <Layouts.Home>
            <PageLoader />
          </Layouts.Home>
        }
      >
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route element={<Layouts.Auth />}>
              <Route path={PagesEnum.SOMETHING_WENT_WRONG} element={<Pages.SomeThingWentWrong />} />
              <Route path={PagesEnum.SIGN_IN} element={<Pages.SignIn />} />
              <Route path={PagesEnum.SIGN_UP} element={<Pages.SignUp />} />
              <Route path={PagesEnum.RESET_PASSWORD} element={<Pages.ResetPassword />} />
            </Route>

            <Route path="*" element={<Navigate to={PagesEnum.SIGN_IN} replace />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<Layouts.Home />}>
              <Route path={PagesEnum.HOME} element={<Pages.Home />} />
            </Route>
            <Route element={<Layouts.CompleteSignUp />}>
              <Route path={PagesEnum.COMPLETE_SIGN_UP} element={<Pages.CompleteSignUp />} />
            </Route>
            <Route element={<Layouts.SearchLayout />}>
              <Route path={PagesEnum.SEARCH} element={<Pages.Search />} />
            </Route>

            <Route element={<Layouts.User />}>
              <Route path={`${PagesEnum.USER}/:id`} element={<Pages.User />} />
            </Route>

            <Route element={<Layouts.User />}>
              <Route path={`${PagesEnum.FOLLOW}/:id`} element={<Pages.UserFollow />} />
            </Route>

            {/* TODO add id to edit profile   */}
            <Route element={<Layouts.EventInfo />}>
              <Route path={`${PagesEnum.EDIT_PROFILE}`} element={<Pages.EditProfile />} />
            </Route>
            <Route element={<Layouts.EventInfo />}>
              <Route path={`${PagesEnum.EVENT_INFO}/:id`} element={<Pages.EventInfo />} />
            </Route>
            <Route element={<Layouts.User />}>
              <Route path={`${PagesEnum.PROFILE}`} element={<Pages.Profile />} />
            </Route>
            <Route element={<Layouts.User />}>
              <Route path={`${PagesEnum.ORDER_TICKETS}/:orderId`} element={<Pages.Ticket />} />
            </Route>
            <Route element={<Layouts.User />}>
              <Route path={`${PagesEnum.EVENT_SUCCESS_PAGE}/:id`} element={<Pages.EventSuccessPage />} />
            </Route>
            <Route element={<Layouts.Checkout />}>
              <Route path={`${PagesEnum.CHECKOUT}/:eventId`} element={<Pages.Checkout />} />
            </Route>
            <Route element={<Layouts.Checkout />}>
              <Route path={PagesEnum.CREATE_EVENT} element={<Pages.EventMutation />} />
            </Route>
            <Route element={<Layouts.Checkout />}>
              <Route path={`${PagesEnum.EDIT_EVENT}/:eventId`} element={<Pages.EventMutation />} />
            </Route>
            <Route path="*" element={<Navigate to={PagesEnum.HOME} replace />} />
          </Route>
        </Routes>
      </Suspense>
    </PageScrollToTop>
  );
};

export default memo(App);
