import { useAppSelector } from '@store/hooks';
import { firebaseUserSelector } from '@store/selectors/authSelector';

const useFirebaseUser = () => {
  const firebaseUser = useAppSelector(firebaseUserSelector);

  return firebaseUser;
};

export default useFirebaseUser;
