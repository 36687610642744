export enum PagesEnum {
  // Private routes
  HOME = '/',
  SEARCH = '/search',
  USER = '/user',
  FOLLOW = '/user-follow',
  CHECKOUT = '/checkout',
  CHECKOUT_CONFIRMED = '/checkout/confirmed',
  WALLET = '/wallet',
  EXPLORE = '/explore',
  EVENT_INFO = '/event',
  CREATE_EVENT = '/create-event',
  EDIT_EVENT = '/edit-event',
  EVENT_SUCCESS_PAGE = '/event-success',
  PROFILE = '/profile',
  ORDER_TICKETS = '/profile/order',
  EDIT_PROFILE = '/edit-profile',

  // Public routes
  SOMETHING_WENT_WRONG = '/something-went-wrong',
  SIGN_IN = '/auth/sign-in',
  SIGN_UP = '/auth/sign-up',
  RESET_PASSWORD = '/auth/reset-password',
  COMPLETE_SIGN_UP = '/auth/complete-sign-up',
}

export enum CompleteSignUpStepEnum {
  CHOOSE_PHOTO = 0,
  DESCRIBE_YOURSELF = 1,
}
