import { useEffect, Dispatch, SetStateAction } from 'react';

import { EventsContent, StorageManipulationKeysEnum, StorageManipulationMethodsEnum } from '@appTypes/global';

import { IInitialCreateEventData } from '@pages/EventMutation/data';
import { getSessionStorageByKey, manipulateStorage } from '@pages/EventMutation/helper';

interface IUseManipulationStorage {
  page: EventsContent;
  prevPage: EventsContent;
  eventData: IInitialCreateEventData;
  isCane: Dispatch<SetStateAction<boolean>>;
  setPrevPage: Dispatch<SetStateAction<EventsContent>>;
  setEventData: Dispatch<SetStateAction<IInitialCreateEventData>>;
}

const useManipulationStorage = ({
  eventData,
  page,
  isCane,
  prevPage,
  setPrevPage,
  setEventData,
}: IUseManipulationStorage) => {
  useEffect(() => {
    const createEvent = getSessionStorageByKey(StorageManipulationKeysEnum.CREATE_EVENTS);

    if (page === EventsContent.BasicEventPage && !createEvent) {
      manipulateStorage({
        value: eventData,
        key: StorageManipulationKeysEnum.CREATE_EVENTS,
        type: StorageManipulationMethodsEnum.CREATE,
      });

      isCane(true);

      return;
    }

    if (createEvent && page !== prevPage) {
      manipulateStorage({
        value: eventData,
        key: StorageManipulationKeysEnum.CREATE_EVENTS,
        type: StorageManipulationMethodsEnum.UPDATE,
      });
      isCane(true);
      setPrevPage(page);
    }

    if (createEvent && page === EventsContent.BasicEventPage) {
      const converted = manipulateStorage({
        key: StorageManipulationKeysEnum.CREATE_EVENTS,
        type: StorageManipulationMethodsEnum.READ,
      });

      const con: IInitialCreateEventData = JSON.parse(converted as unknown as string);

      if (typeof con === 'object' && Object.keys(con).length) {
        isCane(true);
        setEventData(con);
      }

      isCane(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
};

export default useManipulationStorage;
