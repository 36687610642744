export interface ISearchData {
  _key: string;
  name: string;
  link: string;
  avatar?: string;
}

export enum SuggestionViewCaseEnum {
  Empty = '',
  User = 'user',
  Event = 'event',
}

export enum SearchSymbolEnum {
  Empty = '',
  User = '@',
  Event = '#',
}
