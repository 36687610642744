import { useEffect, useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';

import { handleSearchRequest } from './helper';
import { ISearchData, SuggestionViewCaseEnum } from './types';
import { useGetAllEventsLazyQuery, useUserSearchLazyQuery } from '@configs/graphql/generated';
import SearchIcon from '@images/SearchIcon';
import SearchResult from './components/SearchResult';
import { Container, Input } from './styled';

const HeaderSearch = () => {
  let closeTimeId: any = null;
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenSuggestions, setIsOpenSuggestions] = useState<boolean>(false);
  const [lastSearch, setLastSearch] = useState<SuggestionViewCaseEnum>(SuggestionViewCaseEnum.Event);
  const [searchData, setSearchData] = useState<ISearchData[]>([]);

  const [getEvents] = useGetAllEventsLazyQuery();
  const [getPeoples] = useUserSearchLazyQuery();

  const handleOpenSuggestions = () => {
    setIsOpenSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    closeTimeId = setTimeout(() => {
      setIsOpenSuggestions(false);
      setSearchValue('');
      setSearchData([]);
    }, 300);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      handleSearchRequest({
        searchValue,
        getEvents,
        setLoading,
        getPeoples,
        setLastSearch,
        setSearchData,
      });
    }, 500);

    return () => {
      clearTimeout(closeTimeId);
      clearTimeout(time);
    };
  }, [searchValue]);

  return (
    <Container>
      <Input
        value={searchValue}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        onClick={handleOpenSuggestions}
        onBlur={handleCloseSuggestions}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <SearchResult
        loading={loading}
        searchData={searchData}
        lastSearch={lastSearch}
        searchValue={searchValue}
        isOpenSuggestions={isOpenSuggestions}
      />
    </Container>
  );
};

export default HeaderSearch;
