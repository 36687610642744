import InstagramIcon from '@images/InstagramIcon';
import TwitterIcon from '@images/TwitterIcon';
import LinkedinIcon from '@images/LinkedinIcon';

export const navbarItems = [
  {
    id: 'f8c025e0-5035-4dcc-918f-e4bb44f95330',
    name: 'EXPLORE',
    url: 'https://lnqapp.io',
    target: '_blink',
  },
  {
    // url: PagesEnum.HOME, // without _blink
    id: '8b05b961-d9c9-49e4-b984-4c5984622820',
    name: 'HOME',
    url: 'https://lnqapp.io',
    target: '_blink',
  },
  {
    id: '405e5826-2dd7-4087-b903-0b15746ee189',
    name: 'ABOUT',
    url: 'https://lnqapp.io/about/',
    target: '_blink',
  },
  {
    id: '7ff3bb84-55ea-46a9-8140-7198ee038bb2',
    name: 'NEWS',
    url: 'https://lnqapp.io/latest-news/',
    target: '_blink',
  },
  {
    id: 'c62c7597-586d-4623-a56c-cca0555a3a3f',
    name: 'FAQ',
    url: 'https://lnqapp.io/#faq',
    target: '_blink',
  },
  {
    id: '1a39b6ce-9b86-4423-be0b-08b2f3905c3a',
    name: 'CONTACT',
    url: 'https://lnqapp.io/contacts/',
    target: '_blink',
  },
];

export const socialLinks = [
  {
    id: 'ba3376b9-2f6e-42d9-95c3-7f8a253b74e4',
    Icon: LinkedinIcon,
    url: 'https://www.linkedin.com/company/lnq-app',
  },
  {
    id: 'ceb2b267-3b55-41c8-a1fe-1f673518b039',
    Icon: TwitterIcon,
    url: 'https://twitter.com/LNQAPP',
  },
  {
    id: 'a4c80c11-c5cb-4c21-a6c7-2a7b32fbcd54',
    Icon: InstagramIcon,
    url: 'https://www.instagram.com/lnqapp/',
  },
];
