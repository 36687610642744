import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export const StyledAppBar = styled(AppBar)`
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 60px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 300px;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr min-content;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;

  svg {
    ${({ theme }) => theme.breakpoints.down('md')} {
      height: 19.266212463378906px;
      width: 63.16493225097656px;
    }
  }
`;

export const RightPartContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const StyledStack = styled(Stack)`
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const StyledLink = styled(({ isBordered, ...rest }) => <Link {...rest} />)<{ isBordered?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

export const StyledButton = styled(({ isBordered, ...rest }) => <Button {...rest} />)<{ isBordered?: boolean }>`
  height: 48px;
  color: ${({ theme }) => theme.palette.colors.text.white};
  border-radius: 8px;

  ${({ isBordered }) =>
    isBordered
      ? css`
          padding: 0;
          width: 128px;
        `
      : css`
          padding: 0 16px;
        `}

  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.03em;
`;

export const BurgerButton = styled(Button)`
  margin-right: -12px;
  padding: 10px;
  display: none;
  color: ${({ theme }) => theme.palette.colors.text.white};

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: inline-block;
  }
`;
