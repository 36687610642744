import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" fill="none" viewBox="0 0 49 48" {...props}>
    <circle cx="24.701" cy="24" r="23.5" stroke="#fff" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M37.981 16.433c-.931.41-1.93.69-2.98.814a5.186 5.186 0 002.279-2.87 10.39 10.39 0 01-3.293 1.26 5.188 5.188 0 00-8.837 4.73 14.714 14.714 0 01-10.688-5.42 5.17 5.17 0 00-.701 2.606c0 1.799.916 3.386 2.308 4.314a5.174 5.174 0 01-2.35-.649v.065a5.188 5.188 0 004.16 5.084 5.194 5.194 0 01-2.342.087 5.192 5.192 0 004.843 3.601 10.401 10.401 0 01-6.442 2.221c-.418 0-.83-.026-1.237-.071a14.677 14.677 0 007.95 2.33c9.538 0 14.754-7.9 14.754-14.753 0-.227-.004-.45-.015-.672a10.488 10.488 0 002.587-2.685l.004.008z"
      clipRule="evenodd"
    />
  </svg>
);

export default TwitterIcon;
