import { useAppSelector } from '@store/hooks';
import { userSelector } from '@store/selectors/authSelector';

const useUser = () => {
  const user = useAppSelector(userSelector);

  return user;
};

export default useUser;
