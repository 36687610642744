import { ReactElement, memo } from 'react';

import { Outlet } from 'react-router-dom';

import { HomeBackground } from '@common/Backgrounds';
import { Content } from '@layouts/styled';

const HomeLayout = ({ children }: { children?: ReactElement }) => (
  <>
    <HomeBackground />
    <Content>{children || <Outlet />}</Content>
  </>
);

export default memo(HomeLayout);
