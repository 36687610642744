import { useAppSelector } from '@store/hooks';
import { authSelector } from '@store/selectors/authSelector';
import { IAuthState } from '@store/slices/authSlice';

const useAuth = (): IAuthState => {
  const authState = useAppSelector(authSelector);

  return authState;
};

export default useAuth;
