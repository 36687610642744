import { useRef, WheelEvent } from 'react';

import useMount from './useMount';

const useHorizontalScroll = () => {
  const elRef = useRef();

  useMount(() => {
    const el: any = elRef.current;

    if (el) {
      const onWheel = (e: WheelEvent<HTMLDivElement>) => {
        if (e.deltaY === 0) {
          return;
        }

        e.preventDefault();

        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };

      el.addEventListener('wheel', onWheel);

      return () => el.removeEventListener('wheel', onWheel);
    }
  });

  return elRef;
};

export default useHorizontalScroll;
