import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { PublicHeader } from '@shared/Header';
import Footer from '@shared/Footer';

const PublicLayout = () => (
  <>
    <PublicHeader />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>
);

export default memo(PublicLayout);
