// @ts-nocheck

import React from 'react';

import { PagesEnum } from '@appTypes/pages';

import { store } from '@store';
import { logOutRequest } from '@store/slices/authSlice';
import { history } from '@configs/history';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      redirect: false,
    };
  }

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  async componentDidUpdate() {
    if (this.state.hasError) {
      await store.dispatch(logOutRequest());
      this.setState({ redirect: true });
    }
  }

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  async componentDidCatch(error, errorInfo) {
    console.log('Error', error, errorInfo);
  }

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  render() {
    const { redirect, hasError } = this.state;

    if (redirect && hasError) {
      history.push(PagesEnum.SOMETHING_WENT_WRONG);

      this.setState({ redirect: false, hasError: false });

      return;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
