import { Dispatch, SetStateAction } from 'react';

import { EventsContent, StorageManipulationKeysEnum, StorageManipulationMethodsEnum } from '@appTypes/global';

import { history } from '@configs/history';
import { initialCreateEventData } from './data';
import { ICreateEvent, IManipulationOptions } from './types';
import { updateDateByCalendarSelectedHoursAndMinutes } from '@utils/DataManipulation/dateHelper';

export enum MessagesClientSide {
  datePicker = 'Start date must be less than end date.',
}

export const checkStartAndEndDates = (start: ICreateEvent['startDate'], end: ICreateEvent['endDate']) => {
  const { date: startNewDate } = updateDateByCalendarSelectedHoursAndMinutes({ collection: start });
  const { date: endNewDate } = updateDateByCalendarSelectedHoursAndMinutes({ collection: end });

  if (startNewDate?.getTime() >= endNewDate?.getTime()) {
    return true;
  }

  return false;
};

export const manipulateStorage = (options: IManipulationOptions) => {
  const ss = sessionStorage;

  const storageManipulationCollection = {
    [StorageManipulationMethodsEnum.READ]: () => {
      return ss.getItem(options.key);
    },
    [StorageManipulationMethodsEnum.DELETE]: () => ss.removeItem(options.key),
    [StorageManipulationMethodsEnum.CREATE]: () => ss.setItem(options.key, JSON.stringify(options.value)),
    [StorageManipulationMethodsEnum.UPDATE]: () => ss.setItem(options.key, JSON.stringify(options.value)),
    [StorageManipulationMethodsEnum.RESET]: () => ss.setItem(options.key, JSON.stringify(initialCreateEventData)),
  };

  return storageManipulationCollection[options.type]();
};

export const getSessionStorageByKey = (key: StorageManipulationKeysEnum) => {
  return sessionStorage.getItem(key);
};

export const handleNextPage = ({ page, setPage }: { page: number; setPage: Dispatch<SetStateAction<number>> }) => {
  if (page < EventsContent.ReviewPage) {
    setPage(page + 1);
  }
};

export const handleBackPage = ({ page, setPage }: { page: number; setPage: Dispatch<SetStateAction<number>> }) => {
  if (page !== EventsContent.BasicEventPage) {
    setPage(page - 1);
  } else {
    history.push('/');
  }
};
