import { memo } from 'react';

import { BackdropProps } from '@mui/material/Backdrop';

import { LoadingDotsForPage } from '@assets/styles/styleConstants';
import { StyledBackdrop } from './styled';

interface IPageLoaderProps {
  isOpen?: boolean;
  backdropProps?: Omit<BackdropProps, 'open' | 'children'>;
}

const PageLoader = ({ isOpen = true, backdropProps }: IPageLoaderProps) => (
  <StyledBackdrop open={isOpen} {...backdropProps}>
    <LoadingDotsForPage />
  </StyledBackdrop>
);

export default memo(PageLoader);
