import { IStartAndEndDates } from '@pages/EventMutation/types';
import { DAYS_SHORT, SEASONS_SHORT } from '../constants';
import { ICollectDateString, IGetValidDate, IUpdateDateByCalendarSelectedHoursAndMinutes } from '../types';

/**
 * Fixing ISO, if there are month or day like one symbol. For example '1' or '7', but must be '01' or '07'
 * @param date is a ISO string
 * @returns checked ISO
 */

const checkAndFixISO = (date: string) => {
  const splitted = date.split('-');
  const checkedISO = splitted.map((item) => (item.length === 1 ? `0${item}` : item)).join('-');

  return checkedISO;
};

// TODO:
const makeDateAndTimeView = ({
  props: { date: dateISO, weekName = false, clock = false, withoutDate = false },
  data,
}: ICollectDateString): string => {
  const checkedISO = checkAndFixISO(dateISO);

  const altDate = new Date(checkedISO);
  let altHours = `${altDate.getUTCHours()}`;

  const splittedISO: string[] = checkedISO.split('T');

  const [hour, minutes, suffix] = convertTime24to12({
    clock: splittedISO[1].split('.')[0],
    utcHours: altDate.getUTCHours(),
  });

  altHours = `${hour}`.length === 1 ? `0${hour}` : `${hour}`;

  let dateValue: string = '';

  if (checkedISO && !withoutDate) {
    dateValue = `${data.startFormat}`;
  }

  if (weekName) {
    dateValue = `${data.weekDay}, ` + dateValue;
  }

  if (clock) {
    dateValue = dateValue
      ? dateValue + ` | ${altHours}:${data.minutes} ${data.suffix}`
      : dateValue + ` - ${altHours}:${data.minutes} ${data.suffix}`;
  }

  return ` ${dateValue}`;
};

// TODO:
const generateDateByString = (props: { date: string }) => {
  // props.date is ISO here
  // iso checking if calendar return month or date one symbol without 0

  if (!props.date) {
    return {
      startFormat: '',
      weekDay: '',
      hours: '',
      minutes: '',
      suffix: '',
    };
  }

  const checkedISO = checkAndFixISO(props.date);

  const startTimeEST = new Date(checkedISO);

  const [hour, minutes, suffix] = convertTime24to12({
    clock: checkedISO.split('T')[1].split('.')[0],
    onlySuffix: false,
    utcHours: startTimeEST.getUTCHours(),
  });

  const shortMonth = SEASONS_SHORT[startTimeEST.getMonth()];

  const startFormat = `${shortMonth} ${startTimeEST.getDate()} ${startTimeEST.getFullYear()}`;

  const weekDay = DAYS_SHORT[startTimeEST.getDay()];

  return {
    startFormat,
    weekDay,
    hours: hour,
    minutes,
    suffix,
  };
};

export const getDateView = ({ startProps, endProps }: IGetValidDate) => {
  const startDataProps = generateDateByString(startProps);

  let startCollectedDate = makeDateAndTimeView({
    props: {
      ...startProps,
    },
    data: startDataProps,
  });

  if (endProps?.date) {
    const endDataProps = generateDateByString(endProps);
    const endCollectedDate = makeDateAndTimeView({
      props: endProps,
      data: endDataProps,
    });

    startCollectedDate = startCollectedDate + endCollectedDate;
  }

  return startCollectedDate;
};

export const convertDateToISO = (collection: IStartAndEndDates) => {
  const { date } = updateDateByCalendarSelectedHoursAndMinutes({ collection });

  const hou = `${date.getHours()}`;
  const min = `${date.getMinutes()}`;

  const firstFour = `${hou.length === 1 ? `0${hou}` : hou}:${min.length === 1 ? `0${min}` : min}`;

  const lastPart = ':00.000Z';

  const another = `${firstFour} ${collection.timeOfDay}`;

  const clockForBackend = convertTime12to24(another);

  let d = `${date.getDate()}`;
  let m = `${date.getMonth() + 1}`;

  m = `${m}`.length === 1 ? `0${m}` : m;
  d = `${d}`.length === 1 ? `0${d}` : d;

  const y = date.getFullYear();

  const isoForBackend = `${y}-${m}-${d}T${clockForBackend + lastPart}`;

  return isoForBackend;
};

export const convertTime12to24 = (time12h: string) => {
  // example of props "12:00 PM or 02:23 AM"
  const [time, modifier] = time12h.split(' ');

  // eslint-disable-next-line prefer-const
  let [hours, minutes]: string[] = time.split(':');

  if (+hours > 12) {
    return `${hours}:${minutes}`;
  }

  if (hours === '12') {
    hours = '00';
  }

  if (hours !== '12' && modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`;
  }

  return `${hours}:${minutes}`;
};

export const convertTime24to12 = ({
  clock,
  utcHours,
  onlySuffix = false,
}: {
  clock: string | number;
  utcHours: number;
  onlySuffix?: boolean;
}) => {
  const timeTwo = clock;

  let correctFormat = timeTwo.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeTwo];

  if (correctFormat.length > 1) {
    correctFormat = correctFormat.slice(1);
    correctFormat[5] = utcHours < 12 ? 'AM' : 'PM';
    correctFormat[0] = utcHours % 12 || 12;
  }

  const convertedTime = correctFormat.join('');
  const [hour, minutes] = convertedTime.split(':');
  const suffix = convertedTime.slice(-2);

  return onlySuffix ? [suffix] : [hour, minutes, suffix];
};

/**
 * @param collection its comes from calendar chose
 * @returns the new Date() with selected hours and minutes
 */

export const updateDateByCalendarSelectedHoursAndMinutes = ({
  collection,
}: IUpdateDateByCalendarSelectedHoursAndMinutes) => {
  const ch = collection?.hours?.value || '00';
  const cm = collection?.minutes?.value || '00';

  const [cHour, cMin] = convertTime12to24(`${ch}:${cm} ${collection.timeOfDay}`).split(':');

  const NewDate = new Date(collection?.date || Date());

  NewDate.setHours(+cHour);
  NewDate.setMinutes(+cMin);

  return { date: NewDate };
};
