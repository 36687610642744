import { PagesEnum } from '@appTypes/pages';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { isUserExistsSelector } from '@store/selectors/authSelector';
import { logOutRequest } from '@store/slices/authSlice';
// import WalletIcon from '@images/WalletIcon';
import SearchIcon from '@images/SearchIcon';
import { ActionButton, Container, LinkItem, IconContainer } from './styled';

interface INavbarProps {
  fromMobileMenu?: boolean;
  handleCloseMobileMenu?: () => void;
}

const Navbar = ({ fromMobileMenu, handleCloseMobileMenu }: INavbarProps) => {
  const isUserExists = useAppSelector(isUserExistsSelector);
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    handleCloseMobileMenu?.();
    dispatch(logOutRequest());
  };

  const redirect = () => {
    window.open('https://lnqapp.io', '_blink', 'noopener,noreferrer');
  };

  return (
    <Container
      fromMobileMenu={fromMobileMenu}
      direction={fromMobileMenu ? 'column' : 'row'}
      spacing={fromMobileMenu ? 2 : 4}
    >
      {fromMobileMenu ? (
        <>
          {isUserExists && (
            <>
              <ActionButton onClick={handleCloseMobileMenu}>
                <IconContainer>
                  <SearchIcon />
                </IconContainer>
                SEARCH
              </ActionButton>
              <LinkItem to="/create-event" onClick={handleCloseMobileMenu}>
                CREATE
              </LinkItem>
              <LinkItem to="#" onClick={redirect}>
                EXPLORE
              </LinkItem>
            </>
          )}
          <ActionButton onClick={handleSignOut}>LOG OUT</ActionButton>
        </>
      ) : (
        <>
          <LinkItem to="#" onClick={redirect}>
            EXPLORE
          </LinkItem>
          <LinkItem to={PagesEnum.CREATE_EVENT}>CREATE</LinkItem>
        </>
      )}
    </Container>
  );
};

export default Navbar;
