import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const configStoreName = 'configs';

export interface IConfigs {
  youtubeUrl: string;
}

const initialState: IConfigs = {
  youtubeUrl: '',
};

export const configsSlice = createSlice({
  name: configStoreName,
  initialState,
  reducers: {
    setYoutubeUrl: (state, action: PayloadAction<string>) => {
      state.youtubeUrl = action.payload;
    },
  },
});

export const { setYoutubeUrl } = configsSlice.actions;

export default configsSlice.reducer;
