import format from 'date-fns/format';
import { geocodeByAddress } from 'react-places-autocomplete';
import { isSameDay } from 'date-fns';

import type { DateType, IError, NullableDate, NullableOption } from '@appTypes';
import type { IFirebaseUser } from '@appTypes/user';
import type { ILocation, ILocationOption } from '@appTypes/location';
import type { IPagination } from '@appTypes/pagination';

import {
  DATE_FORMAT_WITH_HOUR_AND_MINUTE,
  FIREBASE_ERRORS,
  FIREBASE_SOCIAL_PROVIDERS_LIST,
  GEOCODER_ADDRESS_COMPONENTS,
  GOOGLE_MAPS_PUBLIC_URL,
  INITIAL_PAGINATION,
  MONTH_DAYS,
  SEASONS,
  YEARS,
} from '@utils/constants';
import { IDateCompare, IDobValidation, IGetUserPhotoURL, IChooseViewByPrice, IGetStartAndEndDatesData } from './types';

export const getStartAndEndDateValue = (data: IGetStartAndEndDatesData) => {
  const { startDate, startHour, startMinute, endDate, endHour, endMinute } = data;

  if (!startDate) {
    return '';
  }

  const dates: Date[] = [];

  const startDateString = new Date(startDate);

  if (startHour) {
    startDateString.setHours(Number(startHour.value));

    if (startMinute) {
      startDateString.setMinutes(Number(startMinute.value));
    }
  }

  dates.push(startDateString);

  if (endDate) {
    const endDateString = new Date(endDate);

    if (endHour) {
      endDateString.setHours(Number(endHour.value));

      if (endMinute) {
        endDateString.setMinutes(Number(endMinute.value));
      }
    }

    dates.push(endDateString);
  }

  return dates
    .filter((date) => !!date)
    .map((date) => format(date!, DATE_FORMAT_WITH_HOUR_AND_MINUTE))
    .join(' - ');
};

export const getMonthAndYear = (date: NullableDate) => {
  if (!date) {
    return '';
  }

  return Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
};

export const getNextMonth = (argDate: Date | null): Date => {
  const date = argDate || new Date();
  const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  return newDate;
};

export const handleFirebaseAuthErrors = (error: IError): string => {
  const errorText = error.code ? FIREBASE_ERRORS[error.code] : '';

  return errorText || error.message || 'Something went wrong';
};

export const getIsEmailVerified = (user: IFirebaseUser): boolean => {
  if (!user) {
    return false;
  }

  if (user.providerData.some((provider) => FIREBASE_SOCIAL_PROVIDERS_LIST.includes(provider.providerId))) {
    return true;
  }

  return user.emailVerified;
};

// TODO: Shant remove this after complete sign up integration
export const getFacebookUserPhotoUrl = ({ firebaseUser, accessToken }: IGetUserPhotoURL): string => {
  return `${firebaseUser!.photoURL}?height=500&access_token=${accessToken || ''}`;
};

export const initDebuggerForTesting = () => {
  window.onkeydown = (e) => {
    // NOTE: Option(Alt) + d (This is for testing purposes only)
    if (e.key === '∂') {
      // eslint-disable-next-line no-debugger
      debugger;
    }
  };
};

export const generateTimeOption = (value: string | number) => {
  const newMinute = value < 10 ? `0${value}` : `${value}`;

  return {
    label: `${newMinute}`,
    value: `${newMinute}`,
  };
};

export const sanitizeGooglePlacePredictions = async (
  // eslint-disable-next-line no-undef
  predictions: google.maps.places.AutocompletePrediction[] | null,
): Promise<ILocationOption[]> => {
  if (!predictions) {
    return [];
  }

  const predictionRequests = predictions.map(async ({ description }) => {
    try {
      const [geocodeAddress] = await geocodeByAddress(description);
      const addressField = createLocationAddress(geocodeAddress);

      return addressField;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  });

  const data = await Promise.all(predictionRequests).catch(() => []);

  return data.filter((prediction) => !!prediction) as ILocationOption[];
};

// eslint-disable-next-line no-undef
export const createLocationAddress = (geocodeAddress: google.maps.GeocoderResult): ILocationOption => {
  const {
    address_components,
    formatted_address,
    geometry: { location },
  } = geocodeAddress;

  const addressField: ILocation = {
    address: formatted_address,
    zip: '',
    city: '',
    state: '',
    country: '',
    street: '',
    latitude: location.lat(),
    longitude: location.lng(),
  };

  // eslint-disable-next-line no-undef
  address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
    const type = component.types[0];

    if (GEOCODER_ADDRESS_COMPONENTS.STREET_NUMBER.includes(type)) {
      addressField.street = component.long_name;
    }

    if (GEOCODER_ADDRESS_COMPONENTS.STREET.includes(type)) {
      if (addressField.street) {
        addressField.street = `${addressField.street} ${component.short_name}`;
      } else {
        addressField.street = component.short_name;
      }
    }

    if (GEOCODER_ADDRESS_COMPONENTS.CITY.includes(type)) {
      addressField.city = component.long_name;
    }

    if (GEOCODER_ADDRESS_COMPONENTS.COUNTRY.includes(type)) {
      addressField.country = component.short_name;
    }

    if (GEOCODER_ADDRESS_COMPONENTS.STATE.includes(type)) {
      addressField.state = component.short_name;
    }

    if (GEOCODER_ADDRESS_COMPONENTS.ZIP.includes(type)) {
      addressField.zip = component.long_name;
    }
  });

  return { ...addressField, label: addressField.address, value: addressField.address };
};

export const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');

  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

interface IFakeFetcherResponse<T = unknown> {
  data: T | null;
  pagination: IPagination;
}

export const fakeFetcher = <T = unknown>(data: T | null = null, timeout: number = 500, isReject: boolean = false) => {
  return new Promise<IFakeFetcherResponse<T>>((resolve, reject) =>
    setTimeout(() => (isReject ? reject : resolve)({ data, pagination: INITIAL_PAGINATION }), timeout),
  );
};

export const DateTransformer = {
  fromOptionsToString: ({ year, month, day }: { year: NullableOption; month: NullableOption; day: NullableOption }) => {
    if (year && month && day) {
      const dob: Date | string = new Date();

      dob.setFullYear(Number(year.value), Number(month.value), Number(day.value));

      return dob.toISOString().split('T')[0];
    }

    return null;
  },

  fromStringToOptions: (dob: string | null | undefined) => {
    if (!dob) {
      return { year: null, month: null, day: null };
    }

    const dobDate = new Date(dob);

    const yearOption = YEARS.find(({ value }) => value === `${dobDate.getFullYear()}`) || null;
    const monthOption = SEASONS.find(({ value }) => value === `${dobDate.getMonth()}`) || null;
    const dayOption = MONTH_DAYS.find(({ value }) => value === `${dobDate.getDate()}`) || null;

    return { year: yearOption, month: monthOption, day: dayOption };
  },

  detailedDateRange: (date: DateType, secondDate?: DateType): string => {
    if (!date) {
      return '';
    }

    const timeFormat = 'hh:mm aa';
    const extendedDateFormat = `EEEE, MMM dd, yyyy | ${timeFormat}`;

    let formattedDate = format(new Date(date), extendedDateFormat);

    if (secondDate) {
      if (isSameDay(new Date(date), new Date(secondDate))) {
        formattedDate = `${formattedDate} - ${format(new Date(secondDate), timeFormat)}`;
      } else {
        formattedDate = `${formattedDate} - ${format(new Date(secondDate), extendedDateFormat)}`;
      }
    }

    return formattedDate;
  },
};

export const copyToClipboard = (url: string) => {
  navigator.clipboard.writeText(url);
};

export const getGoogleMapUrl = (address: string | null | undefined) => {
  if (!address) {
    return '';
  }

  return GOOGLE_MAPS_PUBLIC_URL + encodeURIComponent(address);
};

export const dobValidation = ({ values, parentMonth, parentDay }: IDobValidation) => {
  const moreOldThen = 13;
  const day = parentDay;
  const month = parentMonth;
  const year = (values?.value as string) || '';

  if (year && day && month) {
    const Old13 = new Date();

    Old13.setFullYear(Old13.getFullYear() - moreOldThen);

    const dateCompare = ({ old, selected }: IDateCompare): string | boolean => {
      if (selected.year > old.year) {
        return 'Your age is not old enough.';
      } else if (selected.year < old.year) {
        return true;
      }

      if (selected.month < old.month) {
        return 'Your age is not old enough.';
      } else if (selected.month > old.month) {
        return true;
      }

      if (selected.day < old.day) {
        return 'Your age is not old enough.';
      }

      return true;
    };

    return dateCompare({
      old: {
        year: `${Old13.getFullYear()}`,
        month: `${Old13.getMonth()}`,
        day: `${Old13.getDate()}`,
      },
      selected: {
        year,
        month,
        day,
      },
    });
  }

  return 'The year is required.';
};

export const chooseViewByPrice = ({ price, usdIcon = true }: IChooseViewByPrice) => {
  if (price && price !== '0' && price !== 'undefined') {
    return `${usdIcon ? '$' : ''} ${price}`;
  }

  return 'Free';
};
