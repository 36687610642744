import Box from '@mui/material/Box';
import styled from 'styled-components/macro';
import { isSafari } from 'react-device-detect';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgHomeSvg from '@assets/images/background/page-bg-home.svg';
import pageBgHomePng from '@assets/images/background/page-bg-home.png';

export const BigImage = styled(Box)`
  width: 6243px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgHomePng : pageBgHomeSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 6243px 9165px;
  transform: translate(-40%, -2790px);
`;
