import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { EventInfoBackground } from '@common/Backgrounds';
import { Content } from '@layouts/styled';

const EventInfoLayout = () => (
  <>
    <EventInfoBackground />
    <Content>
      <Outlet />
    </Content>
  </>
);

export default memo(EventInfoLayout);
