import { memo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { PagesEnum } from '@appTypes/pages';

import { useAppSelector } from '@store/hooks';
import { isUserExistsSelector } from '@store/selectors/authSelector';
import HeaderLogoIcon from '@images/HeaderLogoIcon';
import BurgerMenuIcon from '@images/BurgerMenuIcon';
import HeaderSearch from '@shared/Header/Private/components/HeaderSearch';
import Navbar from '@shared/Header/Private/components/Navbar';
import UserPart from '@shared/Header/Private/components/UserPart';
import MobileMenu from './components/MobileMenu';
import { BurgerButton, LogoContainer, StyledAppBar, StyledToolbar, StyledStack } from './styled';

const PrivateHeader = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const isUserExists = useAppSelector(isUserExistsSelector);
  const location = useLocation();

  const handleOpenMobileMenu = () => {
    setIsOpenMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setIsOpenMobileMenu(false);
  };

  const gridStyle = isUserExists
    ? location.pathname === PagesEnum.COMPLETE_SIGN_UP
      ? '1fr 75px'
      : '1fr 1fr 1fr'
    : '1fr 75px';

  return (
    <StyledAppBar position="sticky">
      <StyledToolbar exists={gridStyle}>
        <LogoContainer to={PagesEnum.HOME}>
          <HeaderLogoIcon />
        </LogoContainer>
        {isUserExists && <HeaderSearch />}
        <StyledStack direction="row">
          {isUserExists && <Navbar />}
          <UserPart />
        </StyledStack>
        <BurgerButton onClick={handleOpenMobileMenu}>
          <BurgerMenuIcon />
        </BurgerButton>
      </StyledToolbar>
      <MobileMenu isOpenMobileMenu={isOpenMobileMenu} handleCloseMobileMenu={handleCloseMobileMenu} />
    </StyledAppBar>
  );
};

export default memo(PrivateHeader);
