import { SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28" {...props}>
    <rect width="37.521" height="1.876" x="0.14" y="26.673" fill="#fff" rx="0.938" transform="rotate(-45 .14 26.673)" />
    <rect width="37.521" height="1.876" x="1.326" fill="#fff" rx="0.938" transform="rotate(45 1.326 0)" />
  </svg>
);

export default CloseIcon;
