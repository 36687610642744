import styled, { css } from 'styled-components/macro';
import Typography from '@mui/material/Typography';

export const BigBackgroundImageHeight = '999999999px';

export const getGradientBorder = (childrenWithBorder: boolean = true) => css`
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.palette.colors.text.white};
  background: transparent;
  border-radius: 8px;
  border: none;

  &::before {
    content: '';
    padding: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      85.51deg,
      #dfb83e -12.6%,
      #e4993c 14.83%,
      #ec3e32 38.99%,
      #864cc1 71.18%,
      #0f58b5 102.21%
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    border-radius: 8px;
    z-index: 1;
  }

  .children-wrapper {
    position: relative;
    z-index: 1;

    ${childrenWithBorder &&
    css`
      border-radius: 8px;
    `}
  }
`;

const LoaderStyles = css`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.colors.text.darkCharcoal};
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  :before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: linear-gradient(
      88.86deg,
      #dfb83e -17.28%,
      #e4993c -0.91%,
      #ec3e32 21.92%,
      #864cc1 73.15%,
      #0f58b5 85.69%,
      #3389f6 104.99%
    );
    animation: dotFlashing 1s infinite linear alternate;
  }

  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: ${({ theme }) => theme.palette.colors.text.darkCharcoal}
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background: linear-gradient(
        88.86deg,
        #dfb83e -17.28%,
        #e4993c -0.91%,
        #ec3e32 21.92%,
        #864cc1 73.15%,
        #0f58b5 85.69%,
        #3389f6 104.99%
      );
    }

    50% {
      background: ${({ theme }) => theme.palette.colors.text.darkCharcoal}
    }

    100% {
      background: ${({ theme }) => theme.palette.colors.text.darkCharcoal}
    }
  }
`;

export const LoadingDotsForButton = styled(Typography)<{ isGradient?: boolean }>`
  ${LoaderStyles};
`;

export const LoadingDotsForPage = styled(Typography)`
  ${LoaderStyles};
`;
