import { Components, ThemeOptions, Theme } from '@mui/material/styles';

import { FontFamilies } from '@theme/fonts';
import { COLORS } from './palette';

export const typography: ThemeOptions['typography'] = (/* theme */) => ({
  fontFamily: FontFamilies.HalvarBreitschrift,
  fontWeightBold: 700,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
  errorFieldMessageFontSize: '0.75rem',

  allVariants: {
    letterSpacing: 'normal',
  },
});

export const MuiTypography: Components['MuiTypography'] = {};

export const setTypographyMedias = (theme: Theme) => {
  theme.typography = {
    ...theme.typography,
    h1: {
      fontSize: '57px',
      lineHeight: '50px',
      fontWeight: '700',
      letterSpacing: '0.03em',

      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '44px',
      },
    },
    h2: {
      fontWeight: '700',
      fontSize: '42px',
      lineHeight: '48px',
      letterSpacing: '-0.04em',

      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '110%',
      },
    },
    h3: {
      fontWeight: '700',
      fontSize: '21px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',

      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '-0.01em',
      },
    },
    h4: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.03em',

      [theme.breakpoints.down('md')]: {},
    },
    h5: {
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',

      [theme.breakpoints.down('md')]: {
        letterSpacing: '0.01em',
      },
    },
    h6: {},
    subtitle1: {
      fontFamily: FontFamilies.SFCompactText,
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.03em',
      color: COLORS.darkGrayishBlue,

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  };
};
