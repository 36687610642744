import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { ENVIRONMENT } from '@utils/constants';
import configsReducer, { configStoreName } from '@store/slices/configsSlice';
import searchReducer, { searchStoreName } from '@store/slices/searchSlice';
import authReducer, { authStoreName } from '@store/slices/authSlice';
import checkoutReducer, { checkoutStoreName } from '@store/slices/checkoutSlice';
import categoriesReducer, { categoriesStoreName } from '@store/slices/categoriesSlice';

export const store = configureStore({
  devTools: ENVIRONMENT.isDev,
  reducer: {
    [authStoreName]: authReducer,
    [configStoreName]: configsReducer,
    [categoriesStoreName]: categoriesReducer,
    [searchStoreName]: searchReducer,
    [checkoutStoreName]: checkoutReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({ serializableCheck: false });

    if (ENVIRONMENT.isDev) {
      middlewares.concat(logger); // disable this if you needed
    }

    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
