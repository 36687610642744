import { memo } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

import { Container } from './styled';
import { SuggestionViewCaseEnum } from '../../types';

interface ISuggestionItemProps {
  to: string;
  avatar?: string;
  eventName: string;
  lastSearch: SuggestionViewCaseEnum;
}

const SuggestionItem = ({ to, avatar, eventName, lastSearch }: ISuggestionItemProps) => {
  return (
    <Link to={to}>
      <Container>
        {lastSearch === 'user' && <Avatar src={avatar} alt={avatar} />}
        <Box className="username">{eventName}</Box>
      </Container>
    </Link>
  );
};

export default memo(SuggestionItem);
