import createTheme from '@mui/material/styles/createTheme';

import { MuiCssBaseline } from '@theme/cssBaseline';
import { palette } from '@theme/palette';
import { breakpoints } from '@theme/breakpoints';
import { typography, MuiTypography, setTypographyMedias } from '@theme/typography';
import { MuiButton, MuiButtonBase } from '@theme/buttons';
import { MuiInputLabel, MuiOutlinedInput, MuiTextField, MuiFormHelperText, MuiInput } from '@theme/inputs';
import { MuiLink } from '@theme/links';
import { MuiList, MuiMenu, MuiMenuItem } from '@theme/menu';
import { MuiAutocomplete, setAutocompleteMedias } from '@theme/autocomplete';

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiCssBaseline,
    MuiTypography,
    MuiButton,
    MuiButtonBase,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiTextField,
    MuiFormHelperText,
    MuiInput,
    MuiLink,
    MuiList,
    MuiMenu,
    MuiMenuItem,
    MuiAutocomplete,
  },
  breakpoints: {
    values: breakpoints,
  },
});

setTypographyMedias(theme);
setAutocompleteMedias(theme);

export default theme;
