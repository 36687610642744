import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

export const Container = styled(Stack)`
  margin-left: 32px;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 34px 0 0;
  }
`;

export const UserPhotoLink = styled(Link)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 85px;
    height: 85px;
  }

  .MuiAvatar-root {
    width: 100%;
    height: 100%;
  }
`;

export const UserInformationContainer = styled(Box)`
  margin-top: 13px;
  text-align: center;
`;

export const UserDisplayName = styled(Box)`
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.01em;
`;

export const Username = styled(Box)`
  margin-top: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.01em;
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 0;

  a {
    padding: 6px 16px;
    width: 100%;
    display: block;
  }
`;
