/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Nullable } from '@appTypes';

export const checkoutStoreName = 'checkout';

export interface IOrderedTickets {
  [key: string]: any;
}

export interface ICheckoutState {
  event: Nullable<any>;
  tickets: Nullable<any>[];
  buttonLoader: boolean;
  orderedTickets: IOrderedTickets;
}

const initialState: ICheckoutState = {
  event: null,
  tickets: [],
  buttonLoader: false,
  orderedTickets: {},
};

export const checkoutSlice = createSlice({
  name: checkoutStoreName,
  initialState,
  reducers: {
    setCheckoutData: (state, action: PayloadAction<ICheckoutState>) => {
      state.event = action.payload.event;
      state.tickets = action.payload.tickets;
    },
    setButtonLoader: (state, action: PayloadAction<{ status: boolean }>) => {
      state.buttonLoader = action.payload.status;
    },
    setOrderedTickets: (state, action: PayloadAction<IOrderedTickets>) => {
      state.orderedTickets = action.payload;
    },
  },
});

export const { setCheckoutData, setButtonLoader, setOrderedTickets } = checkoutSlice.actions;
export default checkoutSlice.reducer;
