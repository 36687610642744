import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@store';
import { authStoreName } from '@store/slices/authSlice';

const selectAuth = (state: RootState) => state[authStoreName];

export const authSelector = createSelector(selectAuth, (auth) => auth);

export const firebaseUserSelector = createSelector(authSelector, (auth) => auth.firebaseUser);

export const userSelector = createSelector(authSelector, (auth) => auth.user);

export const isFirebaseUserLoadedSelector = createSelector(authSelector, (auth) => auth.isFirebaseUserLoaded);

export const isUserExistsSelector = createSelector(authSelector, (auth) => auth.isUserExists);

export const authIsLoadingSelector = createSelector(authSelector, (auth) => auth.isLoading);

export const authErrorSelector = createSelector(authSelector, (auth) => auth.error);

export const completeSignUpStepSelector = createSelector(authSelector, (auth) => auth.completeSignUpStep);

export const getSignUpMessage = createSelector(authSelector, (auth) => auth.signUpMessage);

export const getAuthBookmarkEvents = createSelector(authSelector, (auth) => auth.bookmarkEvents);

export const getAuthOwnFollowing = createSelector(authSelector, (auth) => auth.ownFollowing);
