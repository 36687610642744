import { memo } from 'react';

import { Outlet } from 'react-router-dom';

// import { useAppSelector } from '@store/hooks';
// import { completeSignUpStepSelector } from '@store/selectors/authSelector';
import { CompleteSignUpBackground } from '@common/Backgrounds';
import { Content } from '@layouts/styled';

const CompleteSignUpLayout = () => {
  // const completeSignUpStep = useAppSelector(completeSignUpStepSelector);

  return (
    <>
      {/* withLine lineSize={completeSignUpStep ? 'thin' : 'fat'} */}
      <CompleteSignUpBackground />
      <Content>
        <Outlet />
      </Content>
    </>
  );
};

export default memo(CompleteSignUpLayout);
