import React from 'react';

const Home = React.lazy(() => import('@pages/Home'));
const Search = React.lazy(() => import('@pages/Search'));
const EventInfo = React.lazy(() => import('@pages/EventInfo'));
const User = React.lazy(() => import('@pages/User'));
const UserFollow = React.lazy(() => import('@pages/UserFollow'));
const Checkout = React.lazy(() => import('@pages/Checkout'));
const EventSuccessPage = React.lazy(() => import('@pages/EventSuccessPage'));
const EventMutation = React.lazy(() => import('@pages/EventMutation'));
const Profile = React.lazy(() => import('@pages/Profile'));
const Ticket = React.lazy(() => import('@pages/Ticket'));
const EditProfile = React.lazy(() => import('@pages/EditProfile'));

const SignIn = React.lazy(() => import('@pages/Auth/SignIn'));
const SignUp = React.lazy(() => import('@pages/Auth/SignUp'));
const SomeThingWentWrong = React.lazy(() => import('@pages/SomeThingWentWrong'));
const ResetPassword = React.lazy(() => import('@pages/Auth/ResetPassword'));
const CompleteSignUp = React.lazy(() => import('@pages/CompleteSignUp'));

export {
  Home,
  Search,
  EventInfo,
  User,
  UserFollow,
  Checkout,
  EventSuccessPage,
  EventMutation,
  Profile,
  Ticket,
  EditProfile,
  SignIn,
  SignUp,
  ResetPassword,
  CompleteSignUp,
  SomeThingWentWrong,
};
