import React from 'react';

import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import '@assets/fonts/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import ErrorBoundary from '@services/ErrorBoundary';
import { history } from '@configs/history';
import { store } from '@store';
import ApolloClient from '@configs/graphql';
import theme from '@theme';
import { ENVIRONMENT } from '@utils/constants';
import { initDebuggerForTesting } from '@utils/helpers';
import reportWebVitals from './reportWebVitals';
import CSSRootScreenSizeVariables from '@common/CSSRootScreenSizeVariables';
import App from './App';

const withStrictMode: boolean = false;
const AppContainer = withStrictMode ? React.StrictMode : React.Fragment;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary>
    <AppContainer>
      <CSSRootScreenSizeVariables />
      <HistoryRouter history={history}>
        <Provider store={store}>
          <ApolloProvider client={ApolloClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
              <ToastContainer />
            </ThemeProvider>
          </ApolloProvider>
        </Provider>
      </HistoryRouter>
    </AppContainer>
  </ErrorBoundary>,
);

if (ENVIRONMENT.isDev) {
  initDebuggerForTesting();
}

reportWebVitals();
