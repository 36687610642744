import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Category } from '@configs/graphql/generated';

export const categoriesStoreName = 'categories';

export interface ICategories {
  categories: Category[];
}

const initialState: ICategories = {
  categories: [],
};

export const categoriesSlice = createSlice({
  name: categoriesStoreName,
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
