import { ISearchData, SuggestionViewCaseEnum } from '../../types';
import { SuggestionsContainer, Suggestion, NameTypography } from '../../styled';
import SuggestionItem from '../SuggestionItem';

interface ISearchResultComponent {
  loading: boolean;
  lastSearch: SuggestionViewCaseEnum;
  searchValue: string;
  searchData: ISearchData[];
  isOpenSuggestions: boolean;
}

const SearchResult = ({ loading, lastSearch, searchValue, searchData, isOpenSuggestions }: ISearchResultComponent) => {
  if (isOpenSuggestions && searchData.length) {
    return (
      <SuggestionsContainer>
        {searchData.map(({ name, link, _key, avatar }) => (
          <SuggestionItem key={_key} to={link} eventName={name} avatar={avatar} lastSearch={lastSearch} />
        ))}
      </SuggestionsContainer>
    );
  }

  if (isOpenSuggestions && !loading && !searchData.length && searchValue) {
    return (
      <SuggestionsContainer>
        <Suggestion>
          <NameTypography>No Result</NameTypography>
        </Suggestion>
      </SuggestionsContainer>
    );
  }

  return null;
};

export default SearchResult;
