import { SVGProps } from 'react';

const BurgerMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="10" fill="none" viewBox="0 0 40 10" {...props}>
    <rect width="40" height="2" fill="#fff" rx="1" />
    <rect width="40" height="2" y="8" fill="#fff" rx="1" />
  </svg>
);

export default BurgerMenuIcon;
