import { Components } from '@mui/material/styles';

import { COLORS, COLORS_OPACITY } from './palette';

export const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    paper: {
      marginTop: '-2px',
      padding: 0,
      maxHeight: '220px',
      background: 'transparent !important',
      boxShadow: 'none',
      transition: 'none !important',
      opacity: 1,

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
};

export const MuiList: Components['MuiList'] = {
  styleOverrides: {
    root: {
      margin: 0,
      padding: '2px 0',
      transition: 'none',
    },
  },
};

export const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      marginTop: '2px',
      color: COLORS.white,
      background: COLORS_OPACITY.white.zeroFive,
      border: `1px solid ${COLORS_OPACITY.white.one}`,
      backdropFilter: 'blur(32px)',
      borderRadius: '4px',

      '&:hover': {
        background: COLORS_OPACITY.white.two,
        border: `1px solid ${COLORS_OPACITY.white.four}`,
      },

      '&.Mui-selected, &.Mui-selected:hover': {
        background: COLORS_OPACITY.white.four,
        border: `1px solid ${COLORS_OPACITY.white.eight}`,

        '&.Mui-disabled': {
          background: COLORS_OPACITY.white.zeroFive,
          border: `1px solid ${COLORS_OPACITY.white.one}`,

          '&.Mui-focusVisible': {
            background: COLORS_OPACITY.white.zeroFive,
            border: `1px solid ${COLORS_OPACITY.white.one}`,
          },
        },

        '&.Mui-focusVisible': {
          background: COLORS_OPACITY.white.six,
        },
      },
    },
  },
};
