export enum FontFamilyNames {
  HalvarBreitschrift = 'Halvar Breitschrift',
  SFCompactText = 'SF Compact Text',
  SFProText = 'SF Pro Text',
}

export const FontFamilies = {
  HalvarBreitschrift: `'${FontFamilyNames.HalvarBreitschrift}', 'Helvetica', 'Arial', sans-serif`,
  SFCompactText: `'${FontFamilyNames.SFCompactText}', 'Helvetica', 'Arial', sans-serif`,
  SFProText: `'${FontFamilyNames.SFProText}', 'Helvetica', 'Arial', sans-serif`,
};
