import styled from 'styled-components/macro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { BurgerButton, LogoContainer } from '../../styled';

export const Container = styled(Box)<{ isOpen: boolean }>`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }

  height: var(--100vh);
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? '0' : 'calc(var(--100vh) * -1.5)')};
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: ${({ isOpen }) => (isOpen ? 99 : -1)};
`;

export const ContentContainer = styled(Box)`
  padding: 25px 16px 40px;
  height: max-content;
  background: linear-gradient(
      180deg,
      rgba(37, 37, 37, 0.5) 0%,
      rgba(91, 91, 91, 0.367188) 49.36%,
      rgba(124, 124, 124, 0) 100%
    ),
    #1f1f1f;
  border-radius: 0px 0px 24px 24px;
`;

export const Heading = styled(Stack)`
  ${LogoContainer} {
    svg {
      width: 79px;
      height: 25px;
    }
  }

  ${BurgerButton} {
    min-width: unset;
    margin-top: -10px;
    margin-right: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
