import { useState, useRef, MouseEvent, memo } from 'react';

import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

import { PagesEnum } from '@appTypes/pages';

import { useAuth } from '@hooks';
import { useAppDispatch } from '@store/hooks';
import { logOutRequest } from '@store/slices/authSlice';
import {
  Container,
  UserInformationContainer,
  UserDisplayName,
  Username,
  UserPhotoLink,
  StyledMenuItem,
} from './styled';

interface IUserPartProps {
  fromMobileMenu?: boolean;
  handleCloseMobileMenu?: () => void;
}

const UserPart = ({ fromMobileMenu, handleCloseMobileMenu }: IUserPartProps) => {
  const dispatch = useAppDispatch();
  const IconRef = useRef<HTMLAnchorElement>(null);
  const { isUserExists, user } = useAuth();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const handleOpenMenu = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleLogOut = () => {
    dispatch(logOutRequest());
    setIsOpenMenu(false);
  };

  const menuContentView = () => {
    if (fromMobileMenu) {
      return (
        <UserInformationContainer>
          <UserDisplayName>{user?.firstName}</UserDisplayName>
          {user?.username && <Username>@{user?.username}</Username>}
        </UserInformationContainer>
      );
    }

    if (isOpenMenu) {
      if (isUserExists) {
        return (
          <Menu
            sx={{ '.MuiPopover-paper': { paddingRight: { md: '32px' } } }}
            open={isOpenMenu}
            anchorEl={IconRef.current}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={handleCloseMenu}
          >
            <StyledMenuItem onClick={handleCloseMenu}>
              <Link to={PagesEnum.PROFILE}>Profile</Link>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleCloseMenu}>
              <Link to={PagesEnum.EDIT_PROFILE}>Edit Profile</Link>
            </StyledMenuItem>
            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
          </Menu>
        );
      }

      return (
        <Menu
          sx={{ '.MuiPopover-paper': { paddingRight: { md: '16px' } } }}
          open={isOpenMenu}
          anchorEl={IconRef.current}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleLogOut}>Log out</MenuItem>
        </Menu>
      );
    }

    return null;
  };

  return (
    <Container>
      <UserPhotoLink
        ref={IconRef}
        to={PagesEnum.PROFILE}
        onClick={fromMobileMenu ? handleCloseMobileMenu : handleOpenMenu}
      >
        <Avatar src={user?.avatar?.uri || ''} alt="user" />
      </UserPhotoLink>

      {menuContentView()}
    </Container>
  );
};

export default memo(UserPart);
