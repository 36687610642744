import Box from '@mui/material/Box';
import styled from 'styled-components/macro';
import { isSafari } from 'react-device-detect';

import { BigBackgroundImageHeight } from '@styles/styleConstants';
import pageBgAuthSvg from '@assets/images/background/page-bg-auth.svg';
import pageBgAuthPng from '@assets/images/background/page-bg-auth.png';

export const BigImage = styled(Box)`
  width: 6243px;
  height: ${BigBackgroundImageHeight};
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url(${isSafari ? pageBgAuthPng : pageBgAuthSvg});
  background-repeat: repeat-y;
  background-size: contain;
  background-size: 6243px 10282px;
  transform: translate(-48%, -3425px);

  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: translate(-34%, -3425px); // TODO change it to image
  }
`;

export const RightOvalsWrapper = styled(Box)`
  position: absolute;
  top: 26px;
  right: -177px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
