import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { CheckoutBackground } from '@common/Backgrounds';
import { Content } from '@layouts/styled';

const CheckoutLayout = () => (
  <>
    <CheckoutBackground />
    <Content>
      <Outlet />
    </Content>
  </>
);

export default memo(CheckoutLayout);
