import { SVGProps } from 'react';

const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
    <circle cx="24" cy="24" r="23.5" stroke="#fff" />
    <path
      fill="#fff"
      d="M18.15 16.065c0 1.141-.925 2.065-2.067 2.065a2.066 2.066 0 112.067-2.065zm.017 3.718H14V33h4.167V19.783zm6.651 0h-4.14V33h4.141v-6.938c0-3.858 5.024-4.174 5.024 0V33H34v-8.37c0-6.509-7.435-6.272-9.182-3.067v-1.78z"
    />
  </svg>
);

export default LinkedinIcon;
