import styled from 'styled-components/macro';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { FontFamilies } from '@theme/fonts';
import { COLORS_OPACITY } from '@theme/palette';

export const Container = styled.footer`
  height: 231px;
  background: ${COLORS_OPACITY.white.zeroFive};
  backdrop-filter: blur(24px);
  border-top: 1px solid;
  border-image-source: linear-gradient(
    85.51deg,
    #dfb83e -12.6%,
    #e4993c 14.83%,
    #ec3e32 38.99%,
    #864cc1 71.18%,
    #0f58b5 102.21%
  );
  border-image-slice: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 319px;
  }
`;

export const Wrapper = styled(Stack)`
  margin: 0 auto;
  padding: 46px 20px;
  max-width: 1090px;
  height: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 33px 16px;
    justify-content: flex-start;
  }
`;

const GridPart = styled(Box)`
  display: grid;
  grid-template-columns: 28.6% auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 100%;
    gap: 29px;
  }
`;

export const TopPart = styled(GridPart)`
  svg {
    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 63.16493225097656px;
      height: 19.266212463378906px;
    }
  }
`;

export const Navbar = styled(Stack)`
  margin-bottom: 0px;
  align-items: flex-end;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: grid;
    grid-template-columns: 45.6% auto;
    gap: 14px 20px;
  }

  a {
    margin-right: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.03em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.01em;
      margin-right: 0;
    }
  }
`;

export const BottomPart = styled(GridPart)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 24px;
    gap: 0;
  }
`;

export const AuthorContainer = styled(Box)`
  font-family: ${FontFamilies.SFCompactText};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.03em;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 27px;
    grid-row: 2;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }
`;

export const SocialLinksContainer = styled(Stack)`
  a {
    margin-right: 14px;
    display: flex;
    border-radius: 50%;

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-right: 16px;

      svg {
        height: 36px;
        width: 36px;
      }
    }
  }
`;
