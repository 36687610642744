import { memo } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthBackground } from '@common/Backgrounds';
import { Content } from '../styled';

const AuthLayout = () => (
  <>
    <AuthBackground />
    <Content>
      <Outlet />
    </Content>
  </>
);

export default memo(AuthLayout);
