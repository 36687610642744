import { memo } from 'react';

import { Link } from 'react-router-dom';

import { navbarItems, socialLinks } from './data';
import FooterLogoIcon from '@images/FooterLogoIcon';
import { AuthorContainer, Container, Wrapper, TopPart, BottomPart, Navbar, SocialLinksContainer } from './styled';

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <TopPart>
          <FooterLogoIcon />
          <Navbar direction="row">
            {navbarItems.map(({ id, name, url, target }) => {
              return target! == '_blink' ? (
                <Link
                  to="#"
                  key={id}
                  onClick={() => {
                    window.open(url, target, 'noopener,noreferrer');
                  }}
                >
                  {name}
                </Link>
              ) : (
                <Link key={id} to={url}>
                  {name}
                </Link>
              );
            })}
          </Navbar>
        </TopPart>
        <BottomPart>
          <AuthorContainer>
            <span>{new Date().getFullYear()} LNQ.</span>
            <br />
            <span>All rights reserved</span>
          </AuthorContainer>
          <SocialLinksContainer direction="row">
            {socialLinks.map(({ id, Icon, url }) => (
              <a key={id} href={url} target="_blank" rel="noreferrer">
                <Icon />
              </a>
            ))}
          </SocialLinksContainer>
        </BottomPart>
      </Wrapper>
    </Container>
  );
};

export default memo(Footer);
