import { PaletteOptions } from '@mui/material';

export const COLORS = {
  white: '#fff',
  brightRed: '#f3474d',
  veryLightGray: '#e4e4e4',
  darkGrayishBlue: '#8e8e93',
  darkCharcoal: '#2f2f2f',
};

export const COLORS_OPACITY = {
  white: {
    white204one: 'rgba(204, 204, 204, 0.1)',
    white204five: 'rgba(204, 204, 204, 0.5)',
    zeroTwo: 'rgba(255, 255, 255, 0.02)',
    zeroFour: 'rgba(255, 255, 255, 0.04)',
    zeroFive: 'rgba(255, 255, 255, 0.05)',
    zero: 'rgba(255, 255, 255)',
    one: 'rgba(255, 255, 255, 0.1)',
    two: 'rgba(255, 255, 255, 0.2)',
    three: 'rgba(255, 255, 255, 0.3)',
    four: 'rgba(255, 255, 255, 0.4)',
    five: 'rgba(255, 255, 255, 0.5)',
    six: 'rgba(255, 255, 255, 0.6)',
    seven: 'rgba(255, 255, 255, 0.7)',
    eight: 'rgba(255, 255, 255, 0.8)',
    nine: 'rgba(255, 255, 255, 0.9)',
  },
};

export const palette = {
  colors: {
    text: {
      white: COLORS.white,
      brightRed: COLORS.brightRed,
      veryLightGray: COLORS.veryLightGray,
      darkGrayishBlue: COLORS.darkGrayishBlue,
    },
    border: {
      white: COLORS.white,
      brightRed: COLORS.brightRed,
      darkGrayishBlue: COLORS.darkGrayishBlue,
    },
    opacity: {
      ...COLORS_OPACITY,
    },
  },
} as PaletteOptions;

// ${COLORS_OPACITY.white.minusOne}
// ${({ theme }) => theme.palette.colors.opacity.white.one};
// ${({ theme }) => theme.palette.colors.text.veryLightGray};
