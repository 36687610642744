import styled from 'styled-components/macro';
import Box from '@mui/material/Box';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Typography } from '@mui/material';

import { FontFamilies } from '@theme/fonts';

export const Container = styled(Box)`
  display: flex;
  max-width: 460px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const Input = styled(OutlinedInput)<OutlinedInputProps>`
  height: 48px;
  width: calc(var(--100vw) * 0.35938);
  padding-left: 16px;

  input {
    font-family: ${FontFamilies.SFCompactText};
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.03em;
  }
`;

export const SuggestionsContainer = styled(Box)`
  width: 100%;
  max-height: 220px;
  position: absolute;
  top: 101%;
  left: 0;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.1);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Suggestion = styled(Box)`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.colors.opacity.white.zeroFive};
  backdrop-filter: blur(32px); // FIXME: attention, it has minimal browser support
  border: 1px solid ${({ theme }) => theme.palette.colors.opacity.white.one};

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const NameTypography = styled(Typography)`
  text-transform: capitalize;
  margin-left: 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
`;
