import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const Container = styled(({ fromMobileMenu, ...restProps }) => <Stack {...restProps} />)<{
  fromMobileMenu?: boolean;
}>`
  align-items: center;

  ${({ fromMobileMenu }) =>
    fromMobileMenu
      ? css`
          margin-top: 27px;
        `
      : css`
          margin-left: 53px;
        `};
`;

const NavItem = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.colors.text.white};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px 4px;
    margin-bottom: -2px;
    font-size: 18px;
    line-height: 178%;
    letter-spacing: -0.02em;
  }
`;

export const LinkItem = styled(Link)`
  display: flex;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.03em;

  ${NavItem};
`;

export const ActionButton = styled(Box)`
  ${NavItem};
`;

export const IconContainer = styled(Box).attrs({ as: 'span' })`
  margin-right: 12px;
  display: flex;
`;
