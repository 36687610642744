import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  signOut,
  UserCredential,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import type { IError, Nullable } from '@appTypes';
import type { IFirebaseUser, IUser } from '@appTypes/user';
import { CompleteSignUpStepEnum, PagesEnum } from '@appTypes/pages';

import { history } from '@configs/history';
import { auth } from '@configs/firebase';
import { handleFirebaseAuthErrors } from '@utils/helpers';
import ApolloClient, { getAndSetUserTokenToApolloClient } from '@configs/graphql';
import { GetOwnFollowingQuery } from '@configs/graphql/generated';

export const authStoreName = 'auth';

interface IAuthRequest {
  email: string;
  password: string;
}

export interface IAuthState {
  signUpMessage: string;
  isFirebaseUserLoaded: boolean;
  isUserLoaded: boolean;
  isUserExists: boolean;
  isLoading: boolean;
  firebaseUser: Nullable<IFirebaseUser>;
  user: Nullable<IUser>;
  token: Nullable<string>;
  error: Nullable<string>;
  completeSignUpStep: CompleteSignUpStepEnum;
  bookmarkEvents: string[];
  ownFollowing: GetOwnFollowingQuery['following'];
}

const initialState: IAuthState = {
  signUpMessage: '',
  isFirebaseUserLoaded: false,
  isUserLoaded: false,
  isUserExists: false,
  isLoading: false,
  firebaseUser: null,
  user: null,
  token: null,
  error: null,
  completeSignUpStep: CompleteSignUpStepEnum.CHOOSE_PHOTO,
  bookmarkEvents: [],
  ownFollowing: [],
};

export const emailSignUp = createAsyncThunk(
  `${authStoreName}/signUp`,
  async ({ email, password }: IAuthRequest, { dispatch }) => {
    dispatch(setAuthIsLoading(true));

    try {
      const { user }: UserCredential = await createUserWithEmailAndPassword(auth, email, password);

      await sendEmailVerification(user);

      dispatch(logOutRequest());
      dispatch(setSignUpMessage(`Please check your email.`));
      history.push(PagesEnum.SIGN_IN);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
      dispatch(setAuthError(error));
    }
  },
);

export const emailSignIn = createAsyncThunk(
  `${authStoreName}/signIn`,
  async ({ email, password }: IAuthRequest, { dispatch }) => {
    dispatch(setAuthIsLoading(true));

    try {
      const { user: firebaseUser }: UserCredential = await signInWithEmailAndPassword(auth, email, password);

      if (!firebaseUser.emailVerified) {
        try {
          await sendEmailVerification(firebaseUser);
          throw new Error();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          throw new Error('Please verify your email');
        }
      }

      const token = await getAndSetUserTokenToApolloClient();

      dispatch(setUserCredentials({ firebaseUser, token, isUserLoaded: false }));
    } catch (error: any) {
      dispatch(setAuthError(error));
    }
  },
);

export const googleSignIn = createAsyncThunk(`${authStoreName}/googleSignIn`, async (_, { dispatch }) => {
  dispatch(setAuthIsLoading(true));

  const googleAuthProvider = new GoogleAuthProvider();

  try {
    const { user: firebaseUser }: UserCredential = await signInWithPopup(auth, googleAuthProvider);

    const token = await getAndSetUserTokenToApolloClient();

    dispatch(setUserCredentials({ firebaseUser, token, isUserLoaded: false }));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    dispatch(setAuthError(error));
  }
});

export const facebookSignIn = createAsyncThunk(`${authStoreName}/facebookSignIn`, async (_, { dispatch }) => {
  dispatch(setAuthIsLoading(true));

  const facebookAuthProvider = new FacebookAuthProvider();

  facebookAuthProvider.addScope('email');
  facebookAuthProvider.addScope('public_profile');

  try {
    const result: UserCredential = await signInWithPopup(auth, facebookAuthProvider);
    const { user: firebaseUser } = result;

    const token = await getAndSetUserTokenToApolloClient();

    dispatch(setUserCredentials({ firebaseUser, token, isUserLoaded: false }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      const email = error?.customData?.email || '';

      const handleCustomSignInByGmail = async () => {
        try {
          const response = await fetchSignInMethodsForEmail(auth, email);

          if (!response.length) {
            throw Error('Response length');
          }

          if (response[0] === 'google.com') {
            const provider = new GoogleAuthProvider();

            provider.setCustomParameters({ login_hint: email });

            const { user: firebaseUser }: UserCredential = await signInWithPopup(auth, provider);

            const token = await getAndSetUserTokenToApolloClient();

            dispatch(setUserCredentials({ firebaseUser, token, isUserLoaded: false }));
          }
        } catch (e: any) {
          console.log('Error: ', e);
        }
      };

      handleCustomSignInByGmail();

      return;
    }

    dispatch(setAuthError({ name: 'facebook', message: 'Login with Facebook temporarily unavailable.' }));
  }
});

export const logOutRequest = createAsyncThunk(`${authStoreName}/logOutRequest`, async (_, { dispatch }) => {
  dispatch(setAuthIsLoading(true));

  try {
    await signOut(auth);
    ApolloClient.resetStore();
    dispatch(resetAuthState());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    dispatch(setAuthError(error));
  }
});

export const authSlice = createSlice({
  name: authStoreName,
  initialState,
  reducers: {
    setSignUpMessage: (state, action: PayloadAction<string>) => {
      state.signUpMessage = action.payload;
    },
    setUser: (state, action: PayloadAction<Nullable<IUser>>) => {
      state.user = action.payload;
      state.isUserExists = !!action.payload;
      state.isUserLoaded = true;
    },
    setUserCredentials: (
      state,
      action: PayloadAction<
        {
          firebaseUser: Nullable<IFirebaseUser>;
          token: Nullable<string>;
          user?: Nullable<IUser>;
          isUserExists?: boolean;
          isUserLoaded: boolean;
        } & Object
      >,
    ) => {
      if (action.payload.hasOwnProperty('user')) {
        state.user = action.payload.user!;
        state.isUserLoaded = true;
      }

      state.isLoading = false;
      state.error = null;
      state.token = action.payload.token;
      state.firebaseUser = action.payload.firebaseUser;
      state.isUserExists = action.payload.isUserExists || false;
      state.isUserLoaded = action.payload.isUserLoaded;
      state.isFirebaseUserLoaded = true;
    },
    setAuthIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isFirebaseUserLoaded = action.payload;
    },
    setAuthIsLoading: (state, action: PayloadAction<boolean>) => {
      state.error = null;
      state.isLoading = action.payload;
    },
    setAuthError: (state, action: PayloadAction<IError>) => {
      state.isFirebaseUserLoaded = true;
      state.isLoading = false;
      state.error = handleFirebaseAuthErrors(action.payload);
    },
    removeAuthError: (state) => {
      state.error = null;
    },
    changeCompleteSignUpStep: (state, action: PayloadAction<CompleteSignUpStepEnum>) => {
      state.completeSignUpStep = action.payload;
    },
    resetAuthState: (state) => {
      // state.signUpMessage = '';
      state.firebaseUser = null;
      state.user = null;
      state.token = null;
      state.error = null;
      state.ownFollowing = [];
      state.isLoading = false;
      state.bookmarkEvents = [];
      state.isUserExists = false;
      state.isFirebaseUserLoaded = true;
      state.completeSignUpStep = CompleteSignUpStepEnum.CHOOSE_PHOTO;
    },
    setBookmarkEvents: (state, action: PayloadAction<{ bookmarkEvents: string[] }>) => {
      state.bookmarkEvents = action.payload.bookmarkEvents;
    },
    updateUserDataWhenUpdatingProfile: (state, action: PayloadAction<any>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setOwnFollowing: (state, action: PayloadAction<GetOwnFollowingQuery['following']>) => {
      state.ownFollowing = action.payload;
    },
  },
});

export const {
  setUser,
  setAuthError,
  resetAuthState,
  setAuthIsLoaded,
  setOwnFollowing,
  removeAuthError,
  setAuthIsLoading,
  setSignUpMessage,
  setBookmarkEvents,
  setUserCredentials,
  changeCompleteSignUpStep,
  updateUserDataWhenUpdatingProfile,
} = authSlice.actions;

export default authSlice.reducer;
